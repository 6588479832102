import { PolicyMap } from "../login/userSlice";
export type CRUDMethod = "GET" | "PUT" | "POST" | "DELETE";
export type SimpleCRUDMethod = "GET" | "EDIT";
export type Role = {
  id: string;
  roleName: string; // e.g. admin
  label: string; // e.g. 管理者
  description: string;
  isReadymadeRole: boolean;
  canEdit: boolean;
  canDelete: boolean;
  order: number;
};
export type Permission = {
  account_id: number;
  company_id: number;
  login_code: string;
  main_company_Code: string | number;
  name: string;
  roles: string;
};
export type SavedRoleApiCondition = {
  api: string;
  target?: number;
  resource?: string[];
  methods: CRUDMethod[];
};
export type RoleApiResource = {
  id: string;
  label: string;
  isView: boolean;
  isSummary: boolean;
};
export type RoleApiCondition = {
  target: number;
  resource: RoleApiResource;
  methods: CRUDMethod[];
};
export type RoleApiBehavior = {
  id: string;
  label: string;
  conditions: RoleApiCondition[];
};
export type RoleBehavior = {
  isReadymadeRole: boolean;
  roleName: string;
  roleDocId: string;
  label: string;
  description: string;
  content: RoleApiBehavior[];
  order: number;
  canEdit: boolean;
  canDelete: boolean;
};
export type APISummary = {
  id: string;
  label: string;
  resource: RoleApiResource[];
};

export const roleTargets = [
  { label: "選択なし", value: -5 },
  { label: "すべて", value: -1 },
  { label: "アクセス可能な部署", value: 7 },
  { label: "部下", value: 5 },
  { label: "自分自身", value: 0 },
];

export const operations = [
  { label: "閲覧", value: "GET" },
  { label: "編集", value: "EDIT" },
] as {
  label: string;
  value: SimpleCRUDMethod;
}[];

export const editMethods = ["PUT", "POST", "DELETE"] as CRUDMethod[];

export const apiSummaries = [
  {
    id: "company_admin_manager",
    label: "企業情報に関する管理者権限",
    resource: [],
  },
  {
    id: "company_manager",
    label: "企業情報に関するユーザー権限",
    resource: [],
  },
  {
    id: "account_admin_manager",
    label: "アカウント情報に関する管理者権限",
    resource: [],
  },
  {
    id: "account_manager",
    label: "アカウント情報に関するユーザー権限",
    resource: [],
  },
  {
    id: "access_manager",
    label: "アクセス制限に関する権限",
    resource: [],
  },
  {
    id: "template_manager",
    label: "マスター管理の操作に関する権限",
    resource: [],
  },
  {
    id: "data_loader",
    label: "データインポートに関する権限",
    resource: [],
  },
  {
    id: "master_data_manager",
    label: "コード情報に関する権限",
    resource: [],
  },
  {
    id: "user_data_manager",
    label: "ユーザー情報に関する権限",
    resource: [],
  },
  {
    id: "user_data_download_manager",
    label: "ユーザー情報ダウンロードに関する権限",
    resource: [],
  },
  {
    id: "application_admin_manager",
    label: "申請書に関する管理者権限",
    resource: [],
  },
  {
    id: "application_manager",
    label: "申請書に関するユーザー権限",
    resource: [],
  },
  {
    id: "profile_manager",
    label: "プロファイルの操作に必ず必要な権限",
    resource: [],
  },
  {
    id: "file_manager",
    label: "ファイルの操作に関する権限",
    resource: [],
  },
  {
    id: "todo_manager",
    label: "ToDo管理に関する権限",
    resource: [],
  },
  {
    id: "activity_manager",
    label: "通知・コメント機能に関する権限",
    resource: [],
  },
  {
    id: "report_admin_manager",
    description: "report_admin_manager.",
    label: "レポートに関する管理者権限",
    resource: [],
  },
  {
    id: "report_manager",
    description: "report_manager.",
    label: "レポートに関するユーザー権限",
    resource: [],
  },
  {
    id: "file_project_admin_manager",
    description: "file_project_admin_manager.",
    label: "ファイル管理に関する管理者権限",
    resource: [],
  },
  {
    id: "file_project_manager",
    description: "file_project_manager.",
    label: "ファイル管理に関するユーザー権限",
    resource: [],
  },
] as APISummary[];

// 常にONである必要がある権限
export const uneditablePolicies = [
  {
    id: "template_manager",
    methods: ["GET"],
  },
  {
    id: "application_manager",
    methods: ["GET", "EDIT"],
  },
  {
    id: "profile_manager",
    methods: ["GET", "EDIT"],
  },
  {
    id: "file_manager",
    methods: ["GET", "EDIT"],
  },
  {
    id: "todo_manager",
    methods: ["GET", "EDIT"],
  },
  {
    id: "activity_manager",
    methods: ["GET", "EDIT"],
  },
  {
    id: "report_manager",
    methods: ["GET", "EDIT"],
  },
  {
    id: "company_manager",
    methods: ["GET"],
  },
  {
    id: "account_manager",
    methods: ["GET"],
  },
] as { id: string; methods: SimpleCRUDMethod[] }[];

// 表示しない権限（権限としてON/OFFの概念がない）
export const undisplayPolicies = [
  {
    id: "file_project_manager",
    methods: ["EDIT"],
  },
] as { id: string; methods: SimpleCRUDMethod[] }[];

export const readymadeRoleDataList = [
  {
    id: "admin",
    roleName: "admin",
    label: "管理者",
    description: "【既定のロール】管理者のロールです。編集できません。",
    order: 0,
    isReadymadeRole: true,
    canEdit: false,
    canDelete: false,
  },
  {
    id: "user",
    roleName: "user",
    label: "一般ユーザー",
    description: "【既定のロール】一般ユーザーのロールです。編集は可能ですが、削除はできません。",
    order: 1,
    isReadymadeRole: false,
    canEdit: true,
    canDelete: false,
  },
] as Role[];

export const PERMISSION_LIST_UPTO = 20;

export type SearchCondition = {
  [key: string]: any;
};

export const isPermitted = (policies: PolicyMap, resource: string, method: CRUDMethod) => {
  const api = resource.split("/")[0];
  if (Object.keys(policies).some((key: string) => key.includes(resource))) {
    /* resource 単位の定義がある場合は該当する resource の定義を参照 */
    if (
      Object.keys(policies)
        .filter((key: string) => key.includes(resource))
        ?.some((_) => policies[_]?.includes(method))
    ) {
      return true;
    } else {
      return false;
    }
    /* resource 単位の定義がない場合は 該当する api の定義を参照 */
  } else if (
    Object.keys(policies)
      .filter((key: string) => api.includes(key))
      ?.some((_) => policies[_]?.includes(method))
  ) {
    return true;
  } else {
    return false;
  }
};
export type ServiceContract = {
  id: number;
  companyId: number;
  isActive: boolean;
  serviceName: string;
  serviceOrder: number;
  serviceType: string;
  serviceId: number;
  startDate: string;
};

export type AssessRole = {
  accountId: number;
  companyId: number;
  currentCompanyCode: string;
  currentCompanyName: string;
  id: number;
  loginCode: string;
  mainCompanyName: string;
  mainCompanyCode: string;
  name: string;
  roleName: string;
  serviceId: number;
};

export const serviceLabels = {
  bpo: {
    id: 1,
    label: "BPOサービス",
    group: "assessment",
  },
  "360-degree-survey": {
    id: 2,
    label: "360度サーベイ",
    group: "assessment",
  },
  evaluation: {
    id: 3,
    label: "人事評価",
    group: "evaluation",
  },
  actionplan: {
    id: 4,
    label: "テーマ",
    group: "assessment",
  },
  "unsigned-survey": {
    id: 5,
    label: "無記名式意識調査",
    group: "assessment",
  },
  "written-exam": {
    id: 6,
    label: "記述式試験",
    group: "assessment",
  },
  inbasket: {
    id: 7,
    label: "インバスケット",
    group: "assessment",
  },
  "signed-survey": {
    id: 8,
    label: "記名式意識調査",
    group: "assessment",
  },
  profile: {
    id: 9,
    label: "人材管理",
    group: "profile",
  },
  "my-number": {
    id: 10,
    label: "マイナンバー",
    group: "profile",
  },
} as {
  [serviceName: string]: {
    id: number;
    label: string;
  };
};

export const getPasswordComplexityText = (i: number) => {
  if (i === 2) {
    return "半角英数が混合している";
  } else if (i === 3) {
    return "半角英数(大文字・小文字)が混合している";
  } else if (i === 4) {
    return "半角英数(大文字・小文字)＋記号が混合している";
  } else {
    return "複雑さを要求しない";
  }
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { ProfileState } from "../profile/profileSlice";
import { call } from "../../app/api";
import dayjs from "dayjs";
import { DecodedFileData } from "../../component/Uploader";

const SLICE_NAME = "myNumber";

export const myNumberStatusDictionary = {
  unregistered: "未登録",
  todo: "登録中",
  reviewing: "承認待ち",
  rejected: "差し戻し中",
  done: "承認済",
  deletable: "削除予定",
} as {
  [status: string]: string;
};

export const myNumberLogActionDictionary = {
  apply: "申請",
  approve: "承認",
  reject: "差し戻し",
  browse: "閲覧",
  set_deletable: "削除予定に設定",
  download: "ダウンロード",
} as {
  [status: string]: string;
};

export const deletionPeriods = [
  { key: "1y", title: "1年" },
  { key: "2y", title: "2年" },
  { key: "3y", title: "3年" },
  { key: "4y", title: "4年" },
  { key: "5y", title: "5年" },
  { key: "6y", title: "6年" },
  { key: "7y", title: "7年" },
];

export type MyNumber = {
  id: string;
  account_id: number;
  type: "self" | "spouse" | "dependent";
  birthday: Date | null;
  dependent_serial: number | null;
  spouse_marriage_on: Date | null;
  value: string | null;
  status: string;
  number_file_id: string | null;
  identification_file_1_id: string | null;
  identification_file_2_id: string | null;
  applied_at: number | null;
  approved_at: number | null;
  rejected_at: number | null;
  expired_at: number | null;
  agreed_at: number | null;
};

export type MyNumberLog = {
  id: string;
  objects: string[];
  subject: number;
  action_type: "apply" | "approved" | "reject" | "browse" | "set_deletable" | "download";
  time: number;
  remarks?: string;
};

export type MyNumberFiles = {
  [columnName: string]: DecodedFileData;
};

export type MyNumberViewWithAccount = { myNumber: MyNumber | null; account: { name: string; retired: boolean } };

interface MyNumberState {
  processing: boolean;
  myNumberViews: MyNumber[];
  selectedMyNumber: MyNumber | null;
  concernedAccountIds: number[];
  logs: MyNumberLog[];
  textForEmployee: string;
  textForFamily: string;
  doDeleteFiles: boolean;
  notificationTemplate: string;
  deletionPeriod: string;
  spouses: any[];
  dependents: any[];
  resigneeAccountIds: number[];
  downloadLogs: MyNumberLog[];
  myNumberValue: string;
  myNumberFiles: MyNumberFiles;
  statusMessages: { [key: string]: string };
}

const initialState: MyNumberState = {
  processing: false,
  myNumberViews: [],
  selectedMyNumber: null,
  concernedAccountIds: [],
  logs: [],
  textForEmployee: "",
  textForFamily: "",
  doDeleteFiles: false,
  notificationTemplate: "",
  deletionPeriod: "",
  spouses: [],
  dependents: [],
  resigneeAccountIds: [],
  downloadLogs: [],
  myNumberValue: "",
  myNumberFiles: {},
  statusMessages: {},
};

export const getMyNumberViews = createAsyncThunk<
  { myNumberViews: MyNumber[]; concernedAccountIds: number[] },
  { accountId?: number; dependentId?: number; spouseId?: number } | void
>(SLICE_NAME + "/getMyNumberViews", async (options) => {
  // - role=adminの場合: サービス「マイナンバー」のユーザー権限ロールを取得
  //   role=userの場合: 自分のアカウントIDを取得
  // - 対応するマイナンバーレコードを取得
  // - 未登録のマイナンバーレコードを補完
  const user = store.getState().user.user;
  const concernedAccountIds = await (async () => {
    if (user.role === "user") return [user.id];
    const res = await call("get", "account_admin_manager/role")({ service_id: 10, role_name: "user" });
    return res.data.result.map((r: any) => r.account_id) as number[];
  })();
  const myNumberRes = await call("get", "my_number_manager/my_number")();
  const myNumberViews = myNumberRes.data.result as MyNumber[];
  return { myNumberViews, concernedAccountIds };
});

export const selectMyNumber = createAsyncThunk(SLICE_NAME + "/selectMyNumber", async ({ id }: { id: string }) => {
  const res = await call("get", "my_number_manager/my_number")({ id });
  const myNumber = res.data.result?.[0] as MyNumber;
  const { number_file_id, identification_file_1_id, identification_file_2_id } = myNumber;
  const myNumberFiles = await Promise.all(
    Object.entries({ number_file_id, identification_file_1_id, identification_file_2_id }).map(
      async ([columnName, id]) => {
        const result = await call("get", "my_number_manager/file")({ id });
        const { file_name, file, mime } = result.data.result;
        return {
          [columnName]: {
            name: file_name,
            dataURI: file,
            type: mime,
            dataURIprefix: `data:${mime};base64`,
          },
        };
      }
    )
  ).then((result) => {
    // すべての非同期処理の結果を一度に処理
    return result.reduce((prev, current) => ({ ...prev, ...current }), {});
  });
  return { myNumber, myNumberFiles };
});

export const attachMyNumberFile = createAsyncThunk(
  SLICE_NAME + "/attachMyNumberFile",
  async ({ fileId, decodedFileData }: { fileId: string; decodedFileData: DecodedFileData }) => {
    const { dataURI, name } = decodedFileData;
    await call("post", "my_number_manager/file")({ id: fileId, file: dataURI, name });
  }
);

export const deleteMyNumberFile = createAsyncThunk(
  SLICE_NAME + "/deleteMyNumberFile",
  async ({ fileId }: { fileId: string }) => {
    await call("delete", "my_number_manager/file")({ id: fileId });
  }
);

export const postMyNumber = createAsyncThunk(
  SLICE_NAME + "/postMyNumber",
  async (data: { accountId?: number; type: string; dependentSerial?: number }) => {
    const res = await call(
      "post",
      "my_number_manager/my_number"
    )({
      account_id: data.accountId,
      type: data.type,
      dependent_serial: data.dependentSerial,
    });
    return res.data.result[0];
  }
);

export const getMyNumber = createAsyncThunk(SLICE_NAME + "/getMyNumber", async ({ id }: { id: string }) => {
  const res = await call("get", "my_number_manager/decrypt")({ id });
  return res.data.result?.[0].value as string;
});

export const applyMyNumber = createAsyncThunk(
  SLICE_NAME + "/applyMyNumber",
  async ({ id, value, agreed_at }: { id: string; value: string; agreed_at?: number }) => {
    const res = await call("post", "my_number_manager/apply")({ id, value, agreed_at });
    return res.data.result[0];
  }
);

export const getMyNumberSettings = createAsyncThunk(SLICE_NAME + "/getMyNumberSettings", async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return {
    deletionPeriod: "6m",
    doDeleteFiles: true,
    textForEmployee: `■ 利用目的の範囲

以下に示す利用目的の範囲内において、ご提供いただきました特定個人情報を利用します。

 
○ 税務

源泉徴収票作成事務

扶養控除等（異動）申告書、保険料控除申告書兼給与所得者の配偶者特別控除申請書作成業務

退職所得に関する申告書作成事務

財産形成住宅貯蓄・財産形成年金貯蓄に関する申告書、届出書及び申込書作成事務

 
○社会保険

健康保険・厚生年金保険届出事務

健康保険・厚生年金保険申請・請求事務

雇用保険・労災保険届出事務

雇用保険・労災保険申請・請求事務

雇用保険・労災保険証明書作成事務`,
    textForFamily: `■ 利用目的の範囲

以下に示す利用目的の範囲内において、ご提供いただきました特定個人情報を利用します。

（配偶者・家族向け）`,
    notificationTemplate: `【マイナンバー登録のお願い】

よろしくお願いします。
`,
  };
});

export const getMyNumberDownloadLogs = createAsyncThunk(SLICE_NAME + "/getMyNumberDownloadLogs", async () => {
  const res = await call(
    "get",
    "operation_log_manager/operation_log"
  )({
    service_id: 10,
    action_type: "download",
    sort_by: '[{ "time": -1 }]',
  });
  return res.data.result;
});

export const getMyNumberLogs = createAsyncThunk<MyNumberLog[], { myNumberId: string }>(
  SLICE_NAME + "/getMyNumberLogs",
  async ({ myNumberId }) => {
    const res = await call(
      "get",
      "operation_log_manager/operation_log"
    )({
      service_id: 10,
      objects: myNumberId,
      sort_by: '[{ "time": -1 }]',
    });
    return res.data.result;
  }
);

export const getStatusMessages = createAsyncThunk(
  SLICE_NAME + "/getStatusMessages",
  async ({ myNumberIds }: { myNumberIds: string[] }) => {
    return await Promise.all(
      myNumberIds.map(async (myNumberId) => {
        const res = await call(
          "get",
          "operation_log_manager/operation_log"
        )({
          service_id: 10,
          objects: myNumberId,
          sort_by: '[{ "time": -1 }]',
          limit: 1,
        });
        return { [myNumberId]: res.data.result[0] };
      })
    ).then((result) => {
      // すべての非同期処理の結果を一度に処理
      return result.reduce((prev, res) => {
        Object.entries(res).forEach(([myNumberId, record]) => {
          prev[myNumberId] = record?.remarks;
        });
        return prev;
      }, {});
    });
  }
);

export const getFamilyData = createAsyncThunk(
  SLICE_NAME + "/getFamilyData",
  async (options?: { account_id: number }) => {
    const [spouses, dependents] = await Promise.all([
      (async () => {
        const res = await call(
          "get",
          `user_data_manager/spouse`
        )(
          options?.account_id
            ? {
                account_id: options.account_id,
                base_date: dayjs().format("YYYY-MM-DD"),
              }
            : {
                base_date: dayjs().format("YYYY-MM-DD"),
              }
        );
        return res.data.result;
      })(),
      (async () => {
        const res = await call(
          "get",
          `user_data_manager/dependent`
        )(
          options?.account_id
            ? {
                account_id: options.account_id,
                base_date: dayjs().format("YYYY-MM-DD"),
              }
            : {
                base_date: dayjs().format("YYYY-MM-DD"),
              }
        );
        return res.data.result;
      })(),
    ]);
    return { spouses, dependents };
  }
);

export const getResignee = createAsyncThunk(SLICE_NAME + "/getResignee", async () => {
  const res = await call(
    "get",
    "user_data_manager/personal"
  )({
    enrollment_type__in: ["退職"],
  });
  const ids = res.data.result.map((r: any) => r.account_id);
  return ids;
});

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearMyNumberValue: (state) => {
      state.myNumberValue = "";
    },
    clearSelectedMyNumber: (state) => {
      state.selectedMyNumber = null;
      state.myNumberFiles = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyNumberViews.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberViews.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberViews.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberViews = action.payload.myNumberViews;
      state.concernedAccountIds = action.payload.concernedAccountIds;
    });
    builder.addCase(selectMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(selectMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(selectMyNumber.fulfilled, (state, action) => {
      state.processing = false;
      state.selectedMyNumber = action.payload.myNumber;
      state.myNumberFiles = action.payload.myNumberFiles;
    });
    builder.addCase(getMyNumber.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumber.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumber.fulfilled, (state, action) => {
      state.processing = false;
      state.myNumberValue = action.payload;
    });
    builder.addCase(getMyNumberLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.logs = action.payload;
    });
    builder.addCase(getMyNumberSettings.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberSettings.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberSettings.fulfilled, (state, action) => {
      state.processing = false;
      state.textForEmployee = action.payload.textForEmployee;
      state.textForFamily = action.payload.textForFamily;
      state.deletionPeriod = action.payload.deletionPeriod;
      state.doDeleteFiles = action.payload.doDeleteFiles;
      state.notificationTemplate = action.payload.notificationTemplate;
    });
    builder.addCase(getFamilyData.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getFamilyData.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getFamilyData.fulfilled, (state, action) => {
      state.processing = false;
      state.spouses = action.payload.spouses;
      state.dependents = action.payload.dependents;
    });
    builder.addCase(getResignee.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getResignee.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getResignee.fulfilled, (state, action) => {
      state.processing = false;
      state.resigneeAccountIds = action.payload;
    });
    builder.addCase(getMyNumberDownloadLogs.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getMyNumberDownloadLogs.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getMyNumberDownloadLogs.fulfilled, (state, action) => {
      state.processing = false;
      state.downloadLogs = action.payload;
    });
    builder.addCase(getStatusMessages.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(getStatusMessages.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(getStatusMessages.fulfilled, (state, action) => {
      state.processing = false;
      state.statusMessages = action.payload;
    });
    builder.addCase(attachMyNumberFile.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(attachMyNumberFile.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(attachMyNumberFile.fulfilled, (state, action) => {
      state.processing = false;
    });
    builder.addCase(deleteMyNumberFile.pending, (state, action) => {
      state.processing = true;
    });
    builder.addCase(deleteMyNumberFile.rejected, (state, action) => {
      state.processing = false;
    });
    builder.addCase(deleteMyNumberFile.fulfilled, (state, action) => {
      state.processing = false;
    });
  },
});

export const { clearMyNumberValue, clearSelectedMyNumber } = slice.actions;

export const selectMyNumberState = (state: RootState) => {
  return state.myNumber as MyNumberState;
};

export const selectMyNumberViewPerAccount = (
  state: RootState
): {
  accountId: number;
  self: MyNumberViewWithAccount;
  spouse: MyNumberViewWithAccount;
  dependent: MyNumberViewWithAccount[];
}[] => {
  // const { user } = state.user as UserState;
  const { accounts, selfAccount } = state.profile as ProfileState;
  const {
    myNumberViews: _views,
    spouses,
    dependents,
    concernedAccountIds,
    resigneeAccountIds,
  } = state.myNumber as MyNumberState;
  const nextViews = [..._views];
  const unregistered = {
    value: null,
    status: "unregistered",
    birthday: null,
    dependent_serial: null,
    spouse_marriage_on: null,
    number_file_id: null,
    identification_file_1_id: null,
    identification_file_2_id: null,
    applied_at: null,
    approved_at: null,
    rejected_at: null,
    expired_at: null,
    agreed_at: null,
  };
  // 未登録のマイナンバーレコードを補完する
  const _accounts = selfAccount ? [...accounts.filter((a) => a.id !== selfAccount.id), selfAccount] : accounts;
  concernedAccountIds.forEach((a, _i) => {
    // if (a.mainCompanyCode !== user.current_company.code) return;
    if (!nextViews.some((v) => v.account_id === a && v.type === "self")) {
      // account に対応するマイナンバーレコードがない
      nextViews.push({
        ...unregistered,
        account_id: a,
        id: `_temp-${a}`,
        type: "self",
      });
    }
  });
  spouses.forEach((s, _i) => {
    // spouse に対応するマイナンバーレコードがない
    if (
      !nextViews.some((v) => {
        const birthday = v.birthday ? dayjs(v.birthday).format("YYYY-MM-DD") : null;
        const marriage_on = v.spouse_marriage_on ? dayjs(v.spouse_marriage_on).format("YYYY-MM-DD") : null;
        return (
          v.account_id === s.account_id &&
          v.type === "spouse" &&
          birthday === s.birthday &&
          marriage_on === s.marriage_on
        );
      })
    ) {
      nextViews.push({
        ...unregistered,
        account_id: s.account_id,
        id: `_temp-spouse-${_i}`,
        type: "spouse",
        birthday: s.birthday,
        spouse_marriage_on: s.marriage_on,
      });
    }
  });
  dependents.forEach((s, _i) => {
    // dependent に対応するマイナンバーレコードがない
    if (
      !nextViews.some((v) => v.account_id === s.account_id && v.type === "dependent" && v.dependent_serial === s.serial)
    ) {
      nextViews.push({
        ...unregistered,
        account_id: s.account_id,
        id: `_temp-dependent-${_i}`,
        type: "dependent",
        dependent_serial: s.serial,
      });
    }
  });

  const views = nextViews.reduce((prev, current) => {
    let assorted = prev.find((a) => a.accountId === current.account_id);
    if (!assorted) {
      assorted = {
        accountId: current.account_id,
        self: { myNumber: null, account: { name: "", retired: resigneeAccountIds.includes(current.account_id) } },
        spouse: { myNumber: null, account: { name: "", retired: resigneeAccountIds.includes(current.account_id) } },
        dependent: [],
      };
    }
    if (current.type === "self") {
      assorted.self.myNumber = current;
    } else if (current.type === "spouse") {
      assorted.spouse.myNumber = current;
    } else {
      assorted.dependent.push({
        myNumber: current,
        account: { name: "", retired: resigneeAccountIds.includes(current.account_id) },
      });
    }
    // Github Actions で 'assorted' is possibly 'undefined' となったので、 assorted => assorted! で型を保証
    return [...prev.filter((a) => a.accountId !== assorted!.accountId), assorted!];
  }, [] as { accountId: number; self: MyNumberViewWithAccount; spouse: MyNumberViewWithAccount; dependent: MyNumberViewWithAccount[] }[]);

  views.forEach((view) => {
    if (view.self.myNumber?.account_id) {
      view.self.account.name = _accounts.find((a) => a.id === view.self.myNumber?.account_id)?.name ?? "";
    }
    if (view.spouse.myNumber?.account_id) {
      const myNumber = view.spouse.myNumber;
      view.spouse.account.name =
        spouses.find((s) => {
          const birthday = myNumber?.birthday ? dayjs(myNumber.birthday).format("YYYY-MM-DD") : null;
          const marriage_on = myNumber?.spouse_marriage_on
            ? dayjs(myNumber.spouse_marriage_on).format("YYYY-MM-DD")
            : null;
          return s.account_id === myNumber?.account_id && s.birthday === birthday && s.marriage_on === marriage_on;
        })?.spouse_name ?? "";
    }
    view.dependent.forEach((d) => {
      if (d.myNumber?.account_id) {
        d.account.name =
          state.myNumber.dependents.find(
            (a: any) => a.account_id === d.myNumber?.account_id && a.serial === d.myNumber?.dependent_serial
          )?.dependent_name ?? "";
      }
    });
  });
  return views;
};

export const selectLogsWithAccount = (state: RootState) => {
  const { accounts } = state.profile as ProfileState;
  const { logs } = state.myNumber as MyNumberState;
  return logs.map((log) => {
    return {
      log,
      account: accounts.find((a) => a.id === log.subject),
    };
  });
};

export const selectDownloadLogsWithAccount = (state: RootState) => {
  const { accounts } = state.profile as ProfileState;
  const { downloadLogs } = state.myNumber as MyNumberState;
  return downloadLogs.map((log) => {
    return {
      ...log,
      account: accounts.find((a) => a.id === log.subject) ?? null,
    };
  });
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;

import { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  addSelectedAccount,
  clearFilteredAccounts,
  clearSelectedAccounts,
  getAccounts,
  searchAccounts,
  selectProfileState,
} from "./profileSlice";
import { ACCOUNT_SEARCH_LIMIT } from "./profileValues";

function MultipleMemberSelector({
  selectedAccountIds,
  onChange,
}: {
  selectedAccountIds: number[];
  onChange: (ids: number[]) => any;
}) {
  const dispatch = useAppDispatch();
  const [state, $state] = useState({
    keyword: "",
    timeoutId: null as number | null,
  });
  const { filteredAccounts, selectedAccounts } = useAppSelector(selectProfileState);

  const onChangeKeyWord = (keyword: string) => {
    // 入力が終わって500ms後に検索のリクエストをする
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      if (keyword) dispatch(searchAccounts({ keyword }));
      else dispatch(clearFilteredAccounts());
      $state({ ...state, timeoutId: null, keyword });
    }, 500);

    $state({ ...state, timeoutId, keyword });
  };

  useEffect(() => {
    return () => {
      dispatch(clearFilteredAccounts());
      dispatch(clearSelectedAccounts());
    };
  }, []);

  useEffect(() => {
    if (selectedAccountIds.length === 0) return;
    if (selectedAccountIds.some((id) => !selectedAccounts.some((account) => account.id === id))) {
      // 選択されたアカウントが取得済みでない場合、取得する
      dispatch(getAccounts(selectedAccountIds));
    }
  }, [selectedAccountIds]);

  return (
    <>
      <Card className="mb-1">
        <Card.Header>選択中のアカウント</Card.Header>
        <Card.Body>
          {selectedAccountIds.length ? (
            <Row>
              {selectedAccountIds.map((id) => {
                const account = selectedAccounts.find((account) => account.id === id);
                return (
                  <Col md="6" key={`account${id}`}>
                    {account?.name}({account?.loginCode})
                    <Button
                      className="ms-1"
                      size="sm"
                      variant="outline-danger"
                      onClick={() => onChange(selectedAccountIds.filter((_id) => _id !== id))}
                    >
                      削除
                    </Button>
                  </Col>
                );
              })}
            </Row>
          ) : (
            "（なし）"
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Form.Control
            type="text"
            value={state.keyword}
            className="mb-1"
            placeholder="キーワード（名前・ログインID）で絞り込む"
            onChange={(e) => onChangeKeyWord(e.target.value)}
          />
          <Row>
            {filteredAccounts.map((account) => {
              // 選択済
              const isSelected = selectedAccountIds.some((id) => id === account.id);
              return (
                <Col md="6" key={`account${account.id}`}>
                  {account.name}({account.loginCode})
                  <Button
                    className="ms-1"
                    size="sm"
                    disabled={isSelected}
                    variant="outline-primary"
                    onClick={() => {
                      dispatch(addSelectedAccount({ account }));
                      onChange([...selectedAccountIds, account.id]);
                    }}
                  >
                    {isSelected ? "追加済" : "追加"}
                  </Button>
                </Col>
              );
            })}
            {filteredAccounts.length === 0 && (
              <Col>該当するアカウントが存在しません。キーワードを変更してください。</Col>
            )}
            {filteredAccounts.length === ACCOUNT_SEARCH_LIMIT && (
              <Col>{`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}</Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default MultipleMemberSelector;

import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import StatisticsTop from "../statistics/StatisticsTop";
import SectionTree from "../client/SectionTree";
import Sidebar from "../../component/Sidebar";
import { selectUserRootRoles, PolicyMap } from "../login/userSlice";

type TAB = {
  label: string;
  eventKey: string;
  isAvailable: (policies: PolicyMap, roles: string[]) => boolean;
};

const TABS: TAB[] = [
  {
    label: "部署",
    eventKey: "sections",
    isAvailable: (policies, _) => {
      return true;
    },
  },
  // {
  //   label: "統計",
  //   eventKey: "statistics",
  //   isAvailable: (policies, _) => {
  //     return true;
  //   },
  // },
];

function PropertyTop() {
  const { mode } = useParams();
  const roles = useAppSelector(selectUserRootRoles);
  const tabPanes = useMemo(() => {
    const tabPanes = TABS.filter((tab) => tab.isAvailable({}, roles));
    return tabPanes;
  }, [roles]);
  const [state, $state] = useState({
    activeTabKey: (() => {
      switch (mode) {
        case "sections":
        case "statistics":
          return mode;
        default:
          return "sections";
      }
    })() as string,
  });

  const tabContent = useMemo(() => {
    if (state.activeTabKey === "sections") return <SectionTree />;
    else if (state.activeTabKey === "statistics") {
      return <StatisticsTop />;
    } else {
      return <SectionTree />;
    }
  }, [state]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"property"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">企業データ</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Tab.Container
            onSelect={(key: string | null) => {
              let next = { ...state };
              if (key) {
                next = { ...next, activeTabKey: key };
                if (key !== "sections") {
                  window.history.replaceState({}, "", `/_/property/${key}`);
                } else {
                  window.history.replaceState({}, "", `/_/property/`);
                }
              }
              $state(next);
            }}
            activeKey={state.activeTabKey}
          >
            <Nav variant="tabs">
              {tabPanes.map((t) => (
                <Nav.Item key={t.eventKey}>
                  <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content>{tabContent}</Tab.Content>
          </Tab.Container>
        </main>
      </div>
    </div>
  );
}

export default PropertyTop;

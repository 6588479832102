export interface MailSetting {
  id: string;
  eta: string;
  name: string;
  status: string;
}

export interface MailSettingDetail extends MailSetting {
  mail_template_id: string;
  from_name: string;
  from_address: string;
  reply_to: string;
  cc: string[];
  bcc: string[];
  target: string;
  conditions: SendMailCondition[];
}

export interface SendMailCondition {
  id__in: string[];
  remarks: string;
  project_id: string;
  work_group_id: string;
  status: string[];
}

export interface SendMailTask {
  count: number;
  receivers: {
    id: string;
    image: string;
    is_active: boolean;
    is_remind: boolean;
    language: string;
    login_code: string;
    mail_address: string;
    main_company_code: string;
    name: string;
    password: string;
    account_id: string;
    company_id: string;
    is_billing: boolean;
    is_guest: boolean;
  }[];
}

export interface Project {
  name: string;
  value: string;
}

export type Workgroup = {
  name: string;
  value: string;
}[];

export interface Account {
  id: string;
  name: string;
  login_code: string;
  mail_address: string;
}

export type SearchCondition = {
  [key: string]: any;
};

export const SEND_STATUS = [
  { name: "未送信", value: "waiting" },
  { name: "未送信（リマインド送信済み）", value: "ready" },
  { name: "送信中", value: "running" },
  { name: "処理中断（メール文面なし）", value: "skipped" },
  { name: "想定外のエラー発生", value: "failed" },
  { name: "送信終了", value: "done" },
];

export const TODO_STATUS = [
  { name: "未実施", value: "waiting" },
  { name: "事前確認済", value: "ready" },
  { name: "実施中", value: "running" },
  { name: "実施済", value: "done" },
];

export const TARGET = [
  { name: "アカウント", value: "account" },
  { name: "ToDoの実施状況", value: "workflow" },
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { MyAccount } from "./myAccountValues";
import { call } from "../../app/api";

const SLICE_NAME = "myAccount";

interface MyAccountState {
  myAccount: MyAccount;
}

const emptyMyAccount: MyAccount = {
  name: "",
  mail_address: "",
  language: "",
  image: "",
  is_remind: false,
};

const initialState: MyAccountState = {
  myAccount: emptyMyAccount,
};

export const getMyAccount = createAsyncThunk(SLICE_NAME + "/getMyAccount", async () => {
  const res = await call("get", "account_manager/my_account")();
  const accounts = res.data.result.map((data: any) => ({
    name: data.name,
    mail_address: data.mail_address,
    image: data.image,
    language: data.language,
    is_remind: data.is_remind,
  })) as MyAccount[];
  return { result: accounts[0] };
});

export const putMyAccount = createAsyncThunk(
  SLICE_NAME + "/putMyAccount",
  async (options: { name: string; mail_address: string; language: string; is_remind: boolean; image: string }) => {
    const res = await call(
      "put",
      "account_manager/my_account"
    )({
      name: options.name,
      mail_address: options.mail_address,
      language: options.language,
      is_remind: options.is_remind,
      image: options.image,
    });
    const r = res.data.result[0];
    const updated = {
      name: r.name,
      image: r.image,
      is_remind: r.is_remind,
      language: r.language,
      mail_address: r.mail_address,
    } as MyAccount;
    return updated;
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyAccount.fulfilled, (state, action) => {
      state.myAccount = action.payload.result;
    });
    builder.addCase(putMyAccount.fulfilled, (state, action) => {
      state.myAccount = action.payload;
    });
  },
});

export const myAccountState = (state: RootState) => {
  return state.myAccount as MyAccountState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;

import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { validateAsync } from "../../app/validator";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Sidebar from "../../component/Sidebar";
import Icon from "../../component/Icon";
import ModalDialog from "../../component/ModalDialog";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import {
  getServiceContracts,
  validateAssessRoles,
  postAssessRoles,
  deleteAssessRoles,
  getAssessRoles,
  selectPermissionState,
  selectSwitchableCompanyRoles,
} from "../permission/permissionSlice";
import { selectClient, selectClientState } from "../client/clientSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { getMembers, putAccount, selectProfileState, reissuePassword } from "../profile/profileSlice";
import { Account, decodeStatusMessage, accountLanguages } from "../profile/profileValues";
import { Container, Row, Col, Button, ListGroup, Form, Dropdown, DropdownButton, Alert } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { testResponse } from "../../app/util";
import { canUseLowQualityMode, rotate } from "../../app/imageModifier";
import { EMAIL_FORMAT_REGEX } from "../../app/validator";
import { AssessRole, serviceLabels } from "../permission/permissionValues";
import classNames from "classnames";
import { setLoading } from "../notification/notificationSlice";

function App() {
  const dispatch = useAppDispatch();
  const { accountId, mode } = useParams();
  const { accounts, selfAccount } = useAppSelector(selectProfileState);
  const { selectedClient } = useAppSelector(selectClientState);
  const current_company = useAppSelector(selectCurrentCompany);
  const { serviceContracts, assessRoles } = useAppSelector(selectPermissionState);
  const switchableCompanyRoles = useAppSelector(selectSwitchableCompanyRoles);
  const [isEditing, $isEditing] = useState(false);
  const [next, $next] = useState({
    name: "",
    loginCode: "",
    mailAddress: "",
    isActive: true,
    language: "ja",
    isRemind: true,
    remarks: "",
  } as {
    name: string;
    loginCode: string;
    mailAddress: string;
    isActive: boolean;
    language: string;
    isRemind: boolean;
    remarks: string;
  });
  const [nextAssessRoles, $nextAssessRoles] = useState([] as AssessRole[]);
  const [activeModal, $activeModal] = useState("");
  const [selectedAccount, $selectedAccount] = useState(null as Account | null);
  const [standByFileUrl, $standByFileUrl] = useState("");
  const [updateErrorMessage, $updateErrorMessage] = useState("");
  const [valueErrors, $valueErrors] = useState(
    {} as {
      [key: string]: string;
    }
  );
  const assessment_services = Object.values(serviceLabels)
    .filter((s: any) => s.group === "assessment")
    .map((s: any) => s.id);
  const profile_services = Object.values(serviceLabels)
    .filter((s: any) => s.group === "profile")
    .map((s: any) => s.id);
  const matchedAccount = useMemo(() => {
    if (!accountId) return null;
    return accounts.find((a) => a.id === +accountId);
  }, [accounts, accountId]);

  useEffect(() => {
    if (matchedAccount) {
      $selectedAccount(matchedAccount);
      dispatch(selectClient({ id: current_company.id }));
      dispatch(getServiceContracts({ companyId: current_company.id })); // ゲストの場合 current_company.id ではない
      dispatch(getAssessRoles({ accountId: matchedAccount.id }));
    }
  }, [current_company, matchedAccount, dispatch]);
  useEffect(() => {
    if (selectedClient) {
      dispatch(getServiceContracts({ companyId: +selectedClient.id }));
    }
  }, [selectedClient]);
  const thisCompanyAssessRoles = useMemo(() => {
    return assessRoles.filter((r) => r.companyId === current_company.id);
  }, [assessRoles]);
  const mainCompanyName = useMemo(() => {
    if (assessRoles.length > 0 && matchedAccount) {
      // ゲストの場合でも大丈夫なように、ロールから企業名を取得する
      return assessRoles.find((r) => r.currentCompanyCode === matchedAccount.mainCompanyCode)?.currentCompanyName ?? "";
    } else {
      return "";
    }
  }, [assessRoles, matchedAccount]);
  useEffect(() => {
    if (accountId) {
      dispatch(
        getMembers({
          accountId: +accountId,
        })
      );
    }
  }, [dispatch]);

  const checked = (roleName: string, serviceName: string) => {
    if (!isEditing) {
      const matchedServiceContract = serviceContracts.find(
        (_) => (_.serviceType === roleName || _.serviceType === "all") && _.serviceName === serviceName
      );
      const matchedRoles = thisCompanyAssessRoles.filter((r) => r.serviceId === matchedServiceContract?.serviceId);
      if (matchedRoles.some((r) => r.roleName === roleName)) {
        return <Icon width={20} height={20} type="check" fill="#19ac48" />;
      } else {
        return <span>- -</span>;
      }
    } else {
      const matchedServiceContract = serviceContracts.find((_) => _.serviceName === serviceName);
      const matchedRoles = nextAssessRoles.filter((r) => r.serviceId === matchedServiceContract?.serviceId);
      return (
        <Form.Check
          key={`checkbox_${roleName}_${serviceName}`}
          type="checkbox"
          disabled={isSelectedAccountSelf && roleName === "admin"}
          checked={matchedRoles.some((r) => r.roleName === roleName)}
          onChange={(e) => {
            if (!matchedServiceContract || !selectedAccount || !selectedClient) return;
            if (e.target.checked) {
              const currentRole = thisCompanyAssessRoles.find(
                (a) => a.serviceId === matchedServiceContract.serviceId && a.roleName === roleName
              );
              $nextAssessRoles([
                ...nextAssessRoles,
                {
                  accountId: selectedAccount.id,
                  companyId: current_company.id,
                  currentCompanyCode: selectedAccount.mainCompanyCode,
                  currentCompanyName: selectedClient.name,
                  id: currentRole ? currentRole.id : -1,
                  loginCode: selectedAccount.loginCode,
                  mainCompanyName: selectedClient.name,
                  mainCompanyCode: selectedAccount.mainCompanyCode,
                  name: selectedAccount.name,
                  roleName: roleName,
                  serviceId: matchedServiceContract.serviceId,
                },
              ]);
            } else {
              $nextAssessRoles(
                nextAssessRoles.filter(
                  (r) => r.serviceId !== matchedServiceContract.serviceId || r.roleName !== roleName
                )
              );
            }
            $valueErrors({
              ...valueErrors,
              assessRoles: "",
            });
          }}
        />
      );
    }
  };

  const accessibleServices = (groupName: string, services: any) => {
    return (
      <div>
        <ListGroup.Item>
          <Row>
            <Col md={3}>
              <div className="--bold">{groupName}</div>
            </Col>
            <Col md={9}>
              {thisCompanyAssessRoles.filter((r) => services.includes(r.serviceId)).length > 0
                ? `利用可 (${thisCompanyAssessRoles
                    .filter((r) => services.includes(r.serviceId))
                    .reduce((prev, current) => {
                      if (!prev.includes(current.roleName)) {
                        return [...prev, current.roleName];
                      } else {
                        return prev;
                      }
                    }, [] as string[])
                    .map((roleName) => (roleName === "admin" ? "管理者" : roleName === "user" ? "ユーザ" : ""))
                    .filter((_) => _)
                    .join(",")})`
                : "利用不可"}
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item className="text-center">
          <Row className="my-2">
            <Col md={6}></Col>
            <Col md={3} className="--bold">
              管理者
            </Col>
            <Col md={3} className="--bold">
              ユーザ
            </Col>
          </Row>
          {serviceContracts
            .filter((_) => services.includes(_.serviceId))
            .map((sc) => {
              return (
                <Row className="my-2" key={sc.serviceOrder}>
                  <Col md={6}>{serviceLabels[sc.serviceName].label}</Col>
                  <Col md={3}>{checked("admin", sc.serviceName)}</Col>
                  <Col md={3}>{[1, 5].includes(sc.serviceId) ? "" : checked("user", sc.serviceName)}</Col>
                </Row>
              );
            })
            .filter((_) => _)}
          {valueErrors.assessRoles && (
            <Row>
              <Col>
                <div className="--text-annotation mt-1 --font-s">{valueErrors.assessRoles}</div>
              </Col>
            </Row>
          )}
        </ListGroup.Item>
      </div>
    );
  };

  const startReissuingPassword = async () => {
    if (!selectedAccount || !selectClient) return;
    const res = testResponse(
      await dispatch(
        reissuePassword({
          account: selectedAccount,
          mainCompanyName,
        })
      )
    );
    if (!res?.payload.ok) {
      $activeModal("passwordReissueError");
    }
    $activeModal("done");
  };

  const isValueError = useMemo(() => {
    return (
      Object.keys(valueErrors)
        .map((key) => valueErrors[key])
        .filter((_) => _).length > 0
    );
  }, [valueErrors]);

  const startWithEditingMode = useMemo(() => {
    return mode === "edit" || window.location.pathname.includes("account/register");
  }, [mode, window.location.pathname]);

  const statusMessageText = useMemo(() => {
    return decodeStatusMessage(selectedAccount?.statusMessage ?? "");
  }, [selectedAccount]);

  const isGuest = useMemo(() => {
    return selectedAccount?.mainCompanyCode !== current_company.code;
  }, [selectedAccount, current_company]);

  const isSelectedAccountSelf = useMemo(() => {
    return selectedAccount?.id === selfAccount?.id;
  }, [selectedAccount, selfAccount]);

  useEffect(() => {
    const valueErrors = {} as { [key: string]: string };
    if (!next.loginCode) {
      valueErrors.loginCode = "入力してください";
    }
    if (!next.name) {
      valueErrors.name = "入力してください";
    }
    if (next.mailAddress && !EMAIL_FORMAT_REGEX.test(next.mailAddress)) {
      valueErrors.mailAddress = "メールアドレスの形式になっていません";
    }
    $valueErrors(valueErrors);
  }, [next]);

  const edit = () => {
    if (!selectedAccount) return;
    $next({
      name: selectedAccount.name,
      loginCode: selectedAccount.loginCode,
      mailAddress: selectedAccount.mailAddress,
      isActive: selectedAccount.isActive,
      language: selectedAccount.language,
      isRemind: selectedAccount.isRemind,
      remarks: selectedAccount.remarks,
    });
    $nextAssessRoles([...thisCompanyAssessRoles]);
    $isEditing(true);
    selectedAccount.image && $standByFileUrl(selectedAccount.image);
    window.history.replaceState({}, "", `/_/account/detail/${selectedAccount.id}/edit`);
  };

  useEffect(() => {
    if (startWithEditingMode && accounts.length > 0) {
      // 編集用URLでアクセスされており、アカウントが取得された直後
      edit();
    }
  }, [selectedAccount, startWithEditingMode]);
  useEffect(() => {
    if (isEditing) {
      // 編集用URLでアクセスされており、ロールが取得された直後
      $nextAssessRoles([...thisCompanyAssessRoles]);
    }
  }, [thisCompanyAssessRoles]);

  const [rolesToDelete, rolesToPost] = useMemo(() => {
    return [
      thisCompanyAssessRoles.filter((old) => {
        return !nextAssessRoles.some((n) => n.id === old.id);
      }),
      nextAssessRoles.filter((role) => {
        return role.id === -1;
      }),
    ];
  }, [thisCompanyAssessRoles, nextAssessRoles]);

  const cancel = () => {
    if (!selectedAccount) return;
    $isEditing(false);
    $standByFileUrl("");
    $valueErrors({});
    $updateErrorMessage("");
    window.history.replaceState({}, "", `/_/account/detail/${selectedAccount.id}`);
  };

  const save = async () => {
    if (!selectedAccount || isValueError) return;
    const nextValueErrors = { ...valueErrors };
    if (next.loginCode !== selectedAccount.loginCode) {
      // ログインIDの重複チェック
      const isValid = await validateAsync({
        key: "unique_login_code",
        value: {
          main_company_code: selectedAccount.mainCompanyCode,
          login_code: next.loginCode,
        },
      });
      if (!isValid) {
        nextValueErrors.loginCode = "ログインIDが他のアカウントと重複しています。";
        $valueErrors(nextValueErrors);
        $updateErrorMessage("ログインIDが他のアカウントと重複しています。");
        $activeModal("updateError");
        return;
      }
    }
    if (nextAssessRoles.length === 0) {
      nextValueErrors.assessRoles = "１つ以上選択してください。";
      $valueErrors(nextValueErrors);
      $updateErrorMessage("利用可能なサービスを１つ以上選択してください。");
      $activeModal("updateError");
      return;
    }

    if (JSON.stringify(nextAssessRoles) !== JSON.stringify(thisCompanyAssessRoles)) {
      const result = testResponse(
        await dispatch(
          validateAssessRoles({
            assessRoles: nextAssessRoles,
            serviceContracts,
            companyId: current_company.id,
            accountId: selectedAccount.id,
          })
        )
      );
      if (!result?.payload.valid) {
        nextValueErrors.assessRoles = result?.payload.message ?? "";
        $valueErrors(nextValueErrors);
        $updateErrorMessage(result?.payload.message ?? "");
        $activeModal("updateError");
        return;
      }
    }

    $activeModal("before_commit");
  };

  const commit = async () => {
    if (!selectedAccount) {
      $activeModal("");
      return;
    }
    try {
      dispatch(setLoading(true));
      await dispatch(
        putAccount({
          id: selectedAccount.id,
          name: next.name,
          loginCode: next.loginCode,
          mailAddress: next.mailAddress,
          mainCompanyCode: selectedAccount.mainCompanyCode,
          isActive: next.isActive,
          language: next.language,
          isRemind: next.isRemind,
          remarks: next.remarks,
          image: standByFileUrl ?? selectedAccount.image,
        })
      );
      if (rolesToPost.length > 0) {
        await dispatch(
          postAssessRoles({
            assessRoles: rolesToPost,
          })
        );
      }
      if (rolesToDelete.length > 0) {
        await dispatch(
          deleteAssessRoles({
            assessRoles: rolesToDelete,
          })
        );
      }
    } catch (e) {
      $updateErrorMessage("処理中にエラーが発生しました。");
    } finally {
      dispatch(setLoading(false));
    }

    window.history.replaceState({}, "", `/_/account/detail/${selectedAccount.id}`);
    $activeModal("saved");
  };

  const onImageUpload = (data: DecodedFileData) => {
    $standByFileUrl(`data:${data.type};base64,${data.dataURI}`);
  };

  const deleteImage = () => {
    $standByFileUrl("");
  };

  const rotateImage = async () => {
    try {
      const { image } = await rotate({
        image: standByFileUrl,
        quality: 1,
      });
      $standByFileUrl(image);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"account"} />
      </div>
      {selectedAccount && (
        <div className="Layout__main">
          <h1 className="Headline--page">アカウント - {selectedAccount.name}</h1>
          <div className="Grouping mt-3 bg-white">
            <Container>
              {!isGuest && (
                <Row className="mb-4">
                  {!isEditing ? (
                    <Col>
                      <Button onClick={edit}>編集</Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button onClick={cancel} variant="outline-danger">
                        キャンセル
                      </Button>
                      <Button onClick={save} disabled={isValueError} className="mx-1">
                        保存
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
              {isEditing && (
                <Row className="mb-2">
                  <Col>
                    <span className="--required-label"></span> は必須項目です。
                  </Col>
                </Row>
              )}
              <ListGroup className="mb-4">
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className={classNames({ "--bold": true, "--required-label": isEditing })}>ログインID</div>
                    </Col>
                    <Col md={9}>
                      {selectedAccount?.isBilling && isEditing ? (
                        <div>
                          <Form.Control
                            type="text"
                            id="loginId"
                            value={next.loginCode}
                            onChange={(e) => {
                              $next({ ...next, loginCode: e.target.value });
                            }}
                          />
                          {valueErrors.loginCode && (
                            <div className="--text-annotation mt-1 --font-s">{valueErrors.loginCode}</div>
                          )}
                        </div>
                      ) : (
                        <div className="--pre-wrap">{selectedAccount.loginCode}</div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className={classNames({ "--bold": true, "--required-label": isEditing })}>名前</div>
                    </Col>
                    <Col md={9}>
                      {isEditing ? (
                        <div>
                          <Form.Control
                            type="text"
                            id="name"
                            value={next.name}
                            onChange={(e) => {
                              $next({ ...next, name: e.target.value });
                            }}
                          />
                          {valueErrors.name && (
                            <div className="--text-annotation mt-1 --font-s">{valueErrors.name}</div>
                          )}
                        </div>
                      ) : (
                        <div className="--pre-wrap --flex --align-items-center">
                          <div>{selectedAccount.name}</div>
                          {mainCompanyName !== current_company.company_name && (
                            <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                          )}
                          {!selectedAccount.isActive && (
                            <div className="mx-1 badge rounded-pill bg-secondary">無効</div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">プロフィール画像</div>
                    </Col>
                    <Col md={9}>
                      <div className="--pre-wrap">
                        {isEditing ? (
                          <div>
                            <figure
                              className={"Profile-card__image mb-2 " + classNames({ "--empty": !standByFileUrl })}
                            >
                              {standByFileUrl && <img src={standByFileUrl} />}
                            </figure>
                            <Uploader onFileLoad={onImageUpload} accepts={["image/jpeg", "image/png"]} />
                            <div className="mt-2">
                              {canUseLowQualityMode && (
                                <Button onClick={rotateImage} variant="outline-primary">
                                  写真を回転
                                </Button>
                              )}
                              <Button onClick={deleteImage} variant="outline-danger" className="mx-1">
                                写真を削除
                              </Button>
                            </div>
                          </div>
                        ) : selectedAccount.image ? (
                          <figure className="Profile-card__image">
                            <img src={selectedAccount.image} />
                          </figure>
                        ) : (
                          "未登録"
                        )}
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">メールアドレス</div>
                    </Col>
                    <Col md={9}>
                      {isEditing ? (
                        <div>
                          <Form.Control
                            type="text"
                            id="mailaddress"
                            value={next.mailAddress}
                            onChange={(e) => {
                              $next({ ...next, mailAddress: e.target.value });
                            }}
                          />
                          {valueErrors.mailAddress && (
                            <div className="--text-annotation mt-1 --font-s">{valueErrors.mailAddress}</div>
                          )}
                        </div>
                      ) : (
                        <div className="--pre-wrap">{selectedAccount.mailAddress}</div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {!isEditing && (
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={3}>
                        <div className="--bold">パスワード</div>
                      </Col>
                      <Col md={9}>
                        {!isGuest ? (
                          selectedAccount.isActive ? (
                            <div className="--pre-wrap">
                              <Button variant="outline-primary" onClick={startReissuingPassword}>
                                パスワード再発行・通知
                              </Button>
                            </div>
                          ) : (
                            <Alert variant="warning" className="my-0">
                              ステータスが無効もしくはこの企業で利用可能なサービスがないため、パスワードの再発行はできません。
                            </Alert>
                          )
                        ) : (
                          <Alert variant="warning" className="my-0">
                            ゲストのパスワードの再発行・通知はできません。
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">ステータス</div>
                    </Col>
                    <Col md={9}>
                      {isEditing ? (
                        <Form.Check
                          type="checkbox"
                          id="isActive"
                          label="有効"
                          checked={next.isActive}
                          onChange={() => {
                            $next({ ...next, isActive: !next.isActive });
                          }}
                          inline
                        />
                      ) : (
                        <div>
                          <div className="--pre-wrap">{selectedAccount.isActive ? "有効" : "無効"}</div>
                          {statusMessageText && (
                            <Alert variant="warning" className="my-0">
                              {statusMessageText}
                            </Alert>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">言語</div>
                    </Col>
                    <Col md={9}>
                      {isEditing ? (
                        <DropdownButton
                          variant="outline-primary"
                          title={accountLanguages.find((_l) => _l.value === next.language)?.label ?? "言語を選択"}
                        >
                          {accountLanguages.map((l, i) => {
                            return (
                              <Dropdown.Item
                                key={`target_${l.value}_${i}`}
                                onClick={() => {
                                  $next({ ...next, language: l.value });
                                }}
                              >
                                {l.label}
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                      ) : (
                        <div className="--pre-wrap">
                          {selectedAccount.language === "ja"
                            ? "日本語"
                            : selectedAccount.language === "en"
                            ? "英語"
                            : selectedAccount.language}
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">リマインドメール</div>
                    </Col>
                    <Col md={9}>
                      {isEditing ? (
                        <Form.Check
                          type="checkbox"
                          id="isRemind"
                          label="有効"
                          checked={next.isRemind}
                          onChange={() => {
                            $next({ ...next, isRemind: !next.isRemind });
                          }}
                          inline
                        />
                      ) : (
                        <div className="--pre-wrap">{selectedAccount.isRemind ? "有効" : "無効"}</div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">ログイン時 企業コード</div>
                    </Col>
                    <Col md={9}>
                      <div className="--pre-wrap">{selectedAccount.mainCompanyCode}</div>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">ログイン時 企業名</div>
                    </Col>
                    <Col md={9}>
                      <div className="--pre-wrap">{mainCompanyName}</div>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={3}>
                      <div className="--bold">備考</div>
                    </Col>
                    <Col md={9}>
                      {isEditing ? (
                        <Form.Control
                          type="text"
                          id="remarks"
                          value={next.remarks}
                          onChange={(e) => {
                            $next({ ...next, remarks: e.target.value });
                          }}
                        />
                      ) : (
                        <div className="--pre-wrap">{selectedAccount.remarks}</div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              <h2 className={classNames({ "Headline--section": true, "mb-2": true, "--required-label": isEditing })}>
                この企業で利用可能なサービス
              </h2>
              {serviceContracts.some((_) => assessment_services.includes(_.serviceId)) && (
                <ListGroup className="mt-4">{accessibleServices("人材開発", assessment_services)}</ListGroup>
              )}
              {serviceContracts.some((_) => profile_services.includes(_.serviceId)) && (
                <ListGroup className="mt-4">{accessibleServices("人事労務", profile_services)}</ListGroup>
              )}
              {!isEditing && <h2 className="Headline--section mb-2 mt-4">切り替え可能な企業</h2>}
              {!isEditing && (
                <ListGroup>
                  <ListGroup.Item>
                    {switchableCompanyRoles.length > 0 ? (
                      switchableCompanyRoles.map((c) => {
                        return <div key={c.companyId}>{c.currentCompanyName}</div>;
                      })
                    ) : (
                      <div>なし</div>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              )}
              {!isGuest && (
                <Row className="mt-4">
                  {!isEditing ? (
                    <Col>
                      <Button onClick={edit}>編集</Button>
                    </Col>
                  ) : (
                    <Col>
                      <Button onClick={cancel} variant="outline-danger">
                        キャンセル
                      </Button>
                      <Button onClick={save} disabled={isValueError} className="mx-1">
                        保存
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
            </Container>
          </div>
        </div>
      )}
      <ModalDialog
        show={activeModal === "done"}
        onConfirm={() => {
          $activeModal("");
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="パスワード再発行のメールをお送りしました。"
        type="confirm"
      />
      <ModalDialog
        show={activeModal === "passwordReissueError"}
        onConfirm={() => {
          $activeModal("");
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="パスワード再発行処理中にエラーが発生しました。"
        type="confirm"
      />
      <ModalDialog
        show={activeModal === "updateError"}
        onConfirm={() => {
          $activeModal("");
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message={updateErrorMessage}
        type="alert"
      />
      <ModalDialog
        show={activeModal === "saved"}
        onConfirm={() => {
          $activeModal("");
          $isEditing(false);
        }}
        onCancel={() => {
          $activeModal("");
          $isEditing(false);
        }}
        message="アカウント情報を更新しました。"
        type="confirm"
      />
      <ModalDialog
        show={activeModal === "before_commit"}
        onCancel={() => {
          $activeModal("");
        }}
        onConfirm={commit}
        message={(() => {
          let message = "更新しますか？";
          if (!next.isActive && selectedAccount?.isActive) {
            message += `\n・${next.name}さんのアカウントを無効にしようとしています。`;
          }
          return message;
        })()}
      />
    </div>
  );
}

export default App;

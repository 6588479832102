import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../../component/Sidebar";
import ProfileListView from "./ProfileListView";
import ProfileMultipleListView from "./ProfileMultipleListView";
import ProfileHistoryListView from "./ProfileHistoryListView";
import "react-calendar/dist/Calendar.css";
import { useAppSelector } from "../../app/store";
import { PolicyMap, selectUserRootRoles, selectUserState } from "../login/userSlice";

type TAB = {
  label: string;
  eventKey: string;
  viewElement: JSX.Element;
  isAvailable: (policies: PolicyMap, roles: string[]) => boolean;
};
const TABS: TAB[] = [
  {
    label: "リスト・検索",
    eventKey: "single",
    viewElement: <ProfileListView />,
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("user_data_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
  {
    label: "通常ダウンロード",
    eventKey: "multiple",
    viewElement: <ProfileMultipleListView />,
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("user_data_download_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
  {
    label: "差分ダウンロード",
    eventKey: "historical",
    viewElement: <ProfileHistoryListView />,
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("user_data_download_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
];

function ProfileListTop() {
  const [activeKey, $activeKey] = useState("single");
  const { mode } = useParams();
  const { policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);

  const tabPanes = useMemo(() => {
    const tabPanes = TABS.filter((tab) => tab.isAvailable(policies, roles));
    return tabPanes;
  }, [roles, policies]);

  useEffect(() => {
    switch (mode) {
      case "multiple":
      case "historical":
        $activeKey(mode);
        break;
      default:
        break;
    }
  }, []);
  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"profiles"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">プロファイル（一覧）</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Tabs
            onSelect={(key) => {
              key && $activeKey(key);
              window.history.replaceState({}, "", `/_/profiles/${key === "single" ? "" : key}`);
            }}
            activeKey={activeKey}
          >
            {tabPanes.map((t) => {
              const content = (() => {
                if (activeKey !== t.eventKey || !t.viewElement) return;
                return t.viewElement;
              })();
              return (
                <Tab eventKey={t.eventKey} title={t.label} key={t.eventKey}>
                  {content}
                </Tab>
              );
            })}
          </Tabs>
        </main>
      </div>
    </div>
  );
}

export default ProfileListTop;

import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { Container, Row, Col, Button, ListGroup, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import classNames from "classnames";
import Sidebar from "../../component/Sidebar";
import ModalDialog from "../../component/ModalDialog";
import { accountLanguages } from "../profile/profileValues";
import { getMyAccount, putMyAccount, myAccountState } from "./myAccountSlice";
import { setLoading } from "../notification/notificationSlice";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import { canUseLowQualityMode, rotate } from "../../app/imageModifier";
import { EMAIL_FORMAT_REGEX } from "../../app/validator";

function App() {
  const dispatch = useAppDispatch();
  const { myAccount } = useAppSelector(myAccountState);
  const [isEditing, $isEditing] = useState(false);
  const [activeModal, $activeModal] = useState("");
  const [standByFileUrl, $standByFileUrl] = useState("");
  const [updateErrorMessage, $updateErrorMessage] = useState("");
  const [valueErrors, $valueErrors] = useState(
    {} as {
      [key: string]: string;
    }
  );
  const [next, $next] = useState({
    name: "",
    mail_address: "",
    language: "",
    is_remind: false,
  } as {
    name: string;
    mail_address: string;
    language: string;
    is_remind: boolean;
  });

  const isValueError = useMemo(() => {
    return (
      Object.keys(valueErrors)
        .map((key) => valueErrors[key])
        .filter((_) => _).length > 0
    );
  }, [valueErrors]);

  const edit = () => {
    if (!myAccount) return;
    $next({
      name: myAccount.name,
      mail_address: myAccount.mail_address,
      language: myAccount.language,
      is_remind: myAccount.is_remind,
    });
    $isEditing(true);
    myAccount.image && $standByFileUrl(myAccount.image);
  };

  const cancel = () => {
    if (!myAccount) return;
    $isEditing(false);
    $standByFileUrl("");
    $updateErrorMessage("");
    $valueErrors({});
  };

  const save = async () => {
    if (!myAccount || isValueError) return;
    $activeModal("before_commit");
  };

  const commit = async () => {
    if (!myAccount) {
      $activeModal("");
      return;
    }
    try {
      dispatch(setLoading(true));
      await dispatch(
        putMyAccount({
          name: next.name,
          mail_address: next.mail_address,
          language: next.language,
          is_remind: next.is_remind,
          image: standByFileUrl,
        })
      );
    } catch (e) {
      $activeModal("updateError");
      $updateErrorMessage("処理中にエラーが発生しました。");
    } finally {
      dispatch(setLoading(false));
      $isEditing(false);
      closeModal();
    }
  };

  const onImageUpload = (data: DecodedFileData) => {
    $standByFileUrl(`data:${data.type};base64,${data.dataURI}`);
    closeModal();
  };

  const deleteImage = () => {
    $standByFileUrl("");
  };

  const rotateImage = async () => {
    try {
      const { image } = await rotate({
        image: standByFileUrl,
        quality: 1,
      });
      $standByFileUrl(image);
    } catch (e) {
      console.error(e);
    }
  };

  const closeModal = () => {
    $activeModal("");
  };

  useEffect(() => {
    dispatch(getMyAccount());
  }, []);

  useEffect(() => {
    const valueErrors = {} as { [key: string]: string };
    if (!next.name) {
      valueErrors.name = "入力してください";
    }
    if (next.mail_address && !EMAIL_FORMAT_REGEX.test(next.mail_address)) {
      valueErrors.mail_address = "メールアドレスの形式になっていません";
    }
    $valueErrors(valueErrors);
  }, [next]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current="dashboard" />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">個人設定</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mb-3">
              <Col>
                {!isEditing ? (
                  <Button onClick={edit}>編集</Button>
                ) : (
                  <>
                    <Button onClick={cancel} variant="outline-danger">
                      キャンセル
                    </Button>
                    <Button onClick={save} disabled={isValueError} className="mx-2">
                      更新
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            {isEditing && (
              <Row className="mb-1">
                <Col>
                  <span className="--required-label"></span> は必須項目です。
                </Col>
              </Row>
            )}
            <Row>
              <Col sm="9">
                <ListGroup className="mb-4">
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className={classNames({ "--bold": true, "--required-label": isEditing })}>名前</div>
                      </Col>
                      <Col md={8}>
                        {isEditing ? (
                          <>
                            <Form.Control
                              type="text"
                              id="name"
                              value={next.name}
                              onChange={(e) => {
                                $next({ ...next, name: e.target.value });
                              }}
                            />
                            {valueErrors.name && (
                              <div className="--text-annotation mt-1 --font-s">{valueErrors.name}</div>
                            )}
                          </>
                        ) : (
                          <div className="--pre-wrap">{myAccount.name}</div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className={classNames({ "--bold": true })}>メールアドレス</div>
                      </Col>
                      <Col md={8}>
                        {isEditing ? (
                          <>
                            <Form.Control
                              type="text"
                              id="mailAddress"
                              value={next.mail_address}
                              onChange={(e) => {
                                $next({ ...next, mail_address: e.target.value });
                              }}
                            />
                            {valueErrors.mail_address && (
                              <div className="--text-annotation mt-1 --font-s">{valueErrors.mail_address}</div>
                            )}
                          </>
                        ) : (
                          <div className="--pre-wrap">{myAccount.mail_address}</div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className="--bold">言語</div>
                      </Col>
                      <Col md={8}>
                        {isEditing ? (
                          accountLanguages.map((l, i) => {
                            return (
                              <Form.Check
                                type="radio"
                                key={`target_${l.value}_${i}`}
                                id={`target_${l.value}_${i}`}
                                label={l.label}
                                value={l.value}
                                checked={next.language === l.value}
                                onChange={(e) => {
                                  $next({ ...next, language: e.target.value });
                                }}
                                inline
                              />
                            );
                          })
                        ) : (
                          <div className="--pre-wrap">
                            {myAccount.language === "ja"
                              ? "日本語"
                              : myAccount.language === "en"
                              ? "英語"
                              : myAccount.language}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="--align-items-center">
                      <Col md={4}>
                        <div className="--bold">リマインドメール</div>
                      </Col>
                      <Col md={8}>
                        {isEditing ? (
                          <Form.Check
                            type="switch"
                            id="isRemind"
                            label={next.is_remind ? "有効" : "無効"}
                            checked={next.is_remind}
                            onChange={() => {
                              $next({ ...next, is_remind: !next.is_remind });
                            }}
                            inline
                          />
                        ) : (
                          <div className="--pre-wrap">{myAccount.is_remind ? "有効" : "無効"}</div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {!isEditing && (
                    <ListGroup.Item>
                      <Row className="--align-items-center">
                        <Col md={4}>
                          <div className={classNames({ "--bold": true })}>パスワード</div>
                        </Col>
                        <Col md={8}>
                          <Link to={"/myaccount/setting/password"}>パスワードを変更</Link>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  {!isEditing && (
                    <ListGroup.Item>
                      <Row className="--align-items-center">
                        <Col md={4}>
                          <div className={classNames({ "--bold": true })}>アカウントトークン</div>
                        </Col>
                        <Col md={6}>
                          <Form.Control type="text" id="token" value="" onChange={() => {}} />
                        </Col>
                        <Col md={2}>
                          <Button variant="outline-secondary" onClick={() => {}}>
                            表示
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
              <Col sm="3">
                <div className="Profile-card text-center">
                  {isEditing ? (
                    <div>
                      <figure className={classNames({ "Profile-card__image": true, "--empty": !standByFileUrl })}>
                        {standByFileUrl && <img src={standByFileUrl} />}
                      </figure>
                      <div className="mt-1">
                        <Link to="" onClick={() => $activeModal("upload")}>
                          写真を変更
                        </Link>
                      </div>
                      {standByFileUrl && canUseLowQualityMode && (
                        <div className="mt-1">
                          <Link to="" onClick={rotateImage}>
                            写真を回転
                          </Link>
                        </div>
                      )}
                      {standByFileUrl && (
                        <div className="mt-1">
                          <Link to="" onClick={deleteImage}>
                            写真を削除
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <figure className={classNames({ "Profile-card__image": true, "--empty": !myAccount.image })}>
                      <img src={myAccount.image} />
                    </figure>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ModalDialog
        show={activeModal === "updateError"}
        onConfirm={() => {
          $activeModal("");
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message={updateErrorMessage}
        type="alert"
      />
      <ModalDialog
        show={activeModal === "before_commit"}
        onCancel={() => {
          $activeModal("");
        }}
        onConfirm={commit}
        message="更新しますか？"
      />
      <Modal
        show={activeModal === "upload"}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <h2 className="Headline--section mb-2">写真の変更</h2>
              <div className="my-2">
                <Uploader onFileLoad={onImageUpload} accepts={["image/jpeg", "image/png"]} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="outline-secondary">
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;

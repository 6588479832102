import { useEffect, useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import Table from "../../component/Table";
import Icon from "../../component/Icon";
import { selectUserState } from "../../features/login/userSlice";
import { getMembers, selectProfileState } from "./profileSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { Row, Col, Alert, Form, Accordion } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);
  const { accounts, accountLoading, allAccountsLoaded } = useAppSelector(selectProfileState);
  const { code: currentCompanyCode } = useAppSelector(selectCurrentCompany);
  useEffect(() => {
    if (!accountLoading) {
      dispatch(getMembers());
    }
  }, [dispatch]);
  const [status, $status] = useState([
    { label: "有効", checked: true, value: true },
    { label: "無効", checked: false, value: false },
  ]);
  const [showGuest, $showGuest] = useState([
    {
      label: "ゲストアカウントも表示する",
      value: "showGuest",
      checked: false,
    },
  ]);
  const filteringConditionsText = useMemo(() => {
    if ([...status, ...showGuest].filter((o) => o.checked).length === 0) return "なし";
    let filteringConditionsText = "";
    if (status.some((o) => o.checked)) {
      filteringConditionsText += `ステータス : ${status
        .filter((o) => o.checked)
        .map((s) => s.label)
        .join(",")}`;
    }
    if (showGuest.some((o) => o.checked)) {
      filteringConditionsText = filteringConditionsText ? `${filteringConditionsText} / ` : "";
      filteringConditionsText += `その他 : ${showGuest
        .filter((o) => o.checked)
        .map((s) => s.label)
        .join(",")}`;
    }
    return filteringConditionsText;
  }, [status, showGuest]);

  const filteredAccounts = useMemo(() => {
    const isStatusChecked = status.some((s) => s.checked);
    const isShowGuestChecked = showGuest.some((s) => s.checked);

    return accounts.filter((a) => {
      // ステータスの条件
      const statusCondition = isStatusChecked
        ? status.some((s) => s.checked && Boolean(s.value) === Boolean(a.isActive))
        : true; // ステータスのチェックがない場合は条件を絞らず全アカウント表示

      // ゲストアカウントも表示するの条件
      const showGuestCondition = isShowGuestChecked
        ? true // チェックがある場合は全アカウント表示
        : a.mainCompanyCode === user.main_company; // チェックがない場合は現在の企業の所属するアカウントのみ表示（ゲストは非表示）

      // 両方の条件を満たす場合
      return statusCondition && showGuestCondition;
    });
  }, [accounts, status, showGuest, user.main_company]);

  return (
    <Row>
      <Col>
        <Row className="mt-2 mb-4">
          <Col md="2">
            <div className="--bold pt-md-3">絞込条件</div>
          </Col>
          <Col md="10">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{filteringConditionsText}</Accordion.Header>
                <Accordion.Body>
                  <Row className="--align-items-center mb-1">
                    <Col md={5}>ステータス：</Col>
                    <Col md={7}>
                      {status.map((o, i) => (
                        <Form.Check
                          type="checkbox"
                          key={`check-${i}`}
                          id={`check-${i}`}
                          label={o.label}
                          checked={o.checked}
                          onChange={() => {
                            $status(
                              status.map((_o) => {
                                return {
                                  ..._o,
                                  checked: _o.label === o.label ? !o.checked : _o.checked,
                                };
                              })
                            );
                          }}
                          inline
                        />
                      ))}
                    </Col>
                  </Row>
                  <Row className="--align-items-center mb-1">
                    <Col md={5}>その他：</Col>
                    <Col md={7}>
                      {showGuest.map((o, i) => (
                        <Form.Check
                          type="checkbox"
                          key={`check-${i}`}
                          id={`other-check-${i}`}
                          label={o.label}
                          checked={o.checked}
                          onChange={() => {
                            $showGuest(
                              showGuest.map((_o) => {
                                return {
                                  ..._o,
                                  checked: _o.label === o.label ? !o.checked : _o.checked,
                                };
                              })
                            );
                          }}
                          inline
                        />
                      ))}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        {accountLoading && filteredAccounts.length === 0 && (
          <div className="--flex --align-items-center --text-light-color">
            <div>
              <Icon type="box-arrow-down" width={24} height={24} />
            </div>
            <div className="--px-2">
              データをダウンロード中です。データ総数によっては 10
              秒以上かかる場合がありますが、この間他の画面に移動しても問題ありません。
            </div>
          </div>
        )}
        {allAccountsLoaded && filteredAccounts.length > 0 ? (
          <Table
            col={[
              {
                name: "氏名",
                colName: "name",
                filterable: true,
              },
              {
                name: "メールアドレス",
                filterable: true,
              },
              {
                name: "ステータス",
                filterable: true,
                width: 100,
              },
              {
                name: "メイン企業コード",
                filterable: true,
              },
            ]}
            row={filteredAccounts.map((a) => {
              return {
                key: a.id,
                data: [a.name, a.mailAddress, a.isActive ? "有効" : "無効", a.mainCompanyCode],
                link: `/_/account/detail/${a.id}`,
                appendAfter: {
                  name: (
                    <>
                      {a.mainCompanyCode !== currentCompanyCode && (
                        <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                      )}
                      {!a.isActive && <div className="mx-1 badge rounded-pill bg-secondary">無効</div>}
                    </>
                  ),
                },
              };
            })}
            usePagenation={true}
            useKeywordFilter={true}
          />
        ) : (
          !accountLoading && <Alert variant={"info"}>該当するアカウント情報がありません。</Alert>
        )}
      </Col>
    </Row>
  );
}

export default App;

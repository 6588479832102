import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { selectCurrentCompany } from "../login/userSlice";
import { downloadAccounts } from "../profile/profileSlice";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useAppDispatch();
  const current_company = useAppSelector(selectCurrentCompany);
  const [processing, $processing] = useState(false);
  const download = async () => {
    if (processing || current_company.id <= 0) return;
    $processing(true);

    await dispatch(
      downloadAccounts({
        companyId: current_company.id,
        format: "xlsx",
      })
    );
    $processing(false);
  };
  return (
    <div>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="my-2">
              現在のアカウント一覧情報を xlsx
              形式でダウンロードできます。このファイルを編集しアップロードすることができます。
            </div>
            <Button variant="outline-primary" onClick={download}>
              ダウンロード
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;

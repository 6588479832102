import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { SEND_STATUS, TODO_STATUS, TARGET } from "./mailSettingValues";
import {
  getMailSetting,
  getSendMailTask,
  getProjects,
  getWorkgroups,
  getAccounts,
  unselectMailSetting,
  selectMailSettingState,
} from "./mailSettingSlice";
import { getMails, getMail, selectMailState } from "../mail/mailSlice";
import { selectCurrentCompany } from "../login/userSlice";
import { Container, Row, Col, Button, ListGroup, Badge, Card } from "react-bootstrap";
import Table from "../../component/Table";
import Sidebar from "../../component/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import classNames from "classnames";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedMailSetting, sendMailTask, projects, workgroups, accounts, projectLosted } =
    useAppSelector(selectMailSettingState);
  const { mails, selectedMail } = useAppSelector(selectMailState);
  const current_company = useAppSelector(selectCurrentCompany);
  const [state, $state] = useState({
    isEditing: false,
  });

  useEffect(() => {
    return () => {
      dispatch(unselectMailSetting());
    };
  }, []);

  useEffect(() => {
    if (id)
      dispatch(getMailSetting({ id })).then((res) => {
        // 存在しないidの場合、メール送信設定一覧へ遷移させる
        if (!res.payload) navigate("/_/mail_setting/");
      });
  }, [id]);

  useEffect(() => {
    if (current_company.id) {
      if (state.isEditing) {
        dispatch(getMails({ conditions: {} }));
      } else if (selectedMailSetting.mail_template_id) {
        dispatch(getMail({ id: selectedMailSetting.mail_template_id }));
      }
    }
  }, [current_company, dispatch, state.isEditing, selectedMailSetting.mail_template_id]);

  useEffect(() => {
    if (state.isEditing) {
      dispatch(getProjects({ conditions: {} }));
    } else {
      if (selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") {
        dispatch(getSendMailTask({ target: selectedMailSetting.target, conditions: selectedMailSetting.conditions }));
      }
      switch (selectedMailSetting.target) {
        case "workflow":
          const projectIds = selectedMailSetting.conditions.map((c) => c.project_id);
          dispatch(getProjects({ conditions: { projectIds } }));
          dispatch(getWorkgroups(selectedMailSetting.conditions));
          break;
        case "account":
          if (selectedMailSetting.conditions[0]?.id__in?.length > 0) {
            dispatch(getAccounts(selectedMailSetting.conditions[0]));
          }
          break;
        default:
          break;
      }
    }
  }, [state.isEditing, selectedMailSetting]);

  const userTimeZone = useMemo(() => {
    return current_company.timezone ?? "Asia/Tokyo";
  }, [current_company]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"mail_setting"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">{selectedMailSetting.name}</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mb-3">
              <Col>
                <Button onClick={() => {}}>編集</Button>
              </Col>
            </Row>
            <ListGroup className="mb-4">
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>タイトル</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">{selectedMailSetting.name}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>メール文面</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">
                      {selectedMail.name ?? (
                        <>
                          <Badge pill bg="danger" className="me-2">
                            !
                          </Badge>
                          <span className="--text-annotation">メール文面が削除されています。</span>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>送信日時</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">{`${dayjs(selectedMailSetting.eta)
                      .tz(userTimeZone)
                      .format("YYYY-MM-DD HH:mm")} (${userTimeZone})`}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>差出人名</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">{selectedMailSetting.from_name}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>差出人メールアドレス</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">{selectedMailSetting.from_address}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>返信先メールアドレス（管理者）</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">{selectedMailSetting.reply_to}</div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>CC</div>
                  </Col>
                  <Col md={8}>
                    <ul>
                      {selectedMailSetting.cc.map((cc, i) => (
                        <li key={`cc_${i}`} className="--pre-wrap">
                          {cc}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>BCC</div>
                  </Col>
                  <Col md={8}>
                    <ul>
                      {selectedMailSetting.bcc.map((bcc, i) => (
                        <li key={`bcc_${i}`} className="--pre-wrap">
                          {bcc}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>ステータス</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">
                      {SEND_STATUS.find((s) => s.value === selectedMailSetting.status)?.name ?? "未送信"}
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
              {(selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") && (
                <ListGroup.Item>
                  <Row className="--align-items-center">
                    <Col md={4}>
                      <div className={classNames({ "--bold": true })}>送信予定件数</div>
                    </Col>
                    <Col md={8}>
                      {projectLosted && selectedMailSetting.target === "workflow" ? (
                        <div className="--pre-wrap">
                          <Badge pill bg="danger" className="me-2">
                            !
                          </Badge>
                          <span className="--text-annotation">プロジェクトが削除されています。</span>
                        </div>
                      ) : (
                        <div className="--pre-wrap">{sendMailTask.count}</div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
            </ListGroup>
            <ListGroup className="mb-4">
              <ListGroup.Item>
                <Row className="--align-items-center">
                  <Col md={4}>
                    <div className={classNames({ "--bold": true })}>送信条件</div>
                  </Col>
                  <Col md={8}>
                    <div className="--pre-wrap">
                      {TARGET.find((t) => t.value === selectedMailSetting.target)?.name ?? ""}
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {(() => {
                  switch (selectedMailSetting.target) {
                    case "workflow":
                      return selectedMailSetting.conditions.map((condition, index) => (
                        <Card key={`condition_${index}`} className="MailSettingConditions-card">
                          <Card.Body>
                            <Row className="mb-2">
                              <Col xs={4}>
                                <div className="MailSettingConditions-card__name">プロジェクト</div>
                              </Col>
                              <Col xs={4}>
                                <div className="MailSettingConditions-card__name">フェーズ</div>
                              </Col>
                              <Col xs={4}>
                                <div className="MailSettingConditions-card__name">ステータス</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <div className="--pre-wrap">
                                  {projects.find((p) => p.value === condition.project_id)?.name ?? (
                                    <>
                                      <Badge pill bg="danger" className="me-2">
                                        !
                                      </Badge>
                                      <span className="--text-annotation">プロジェクトが削除されています。</span>
                                    </>
                                  )}
                                </div>
                              </Col>
                              <Col xs={4}>
                                <div className="--pre-wrap">
                                  {workgroups[index]?.find((w) => w.value === condition.work_group_id)?.name ?? ""}
                                </div>
                              </Col>
                              <Col xs={4}>
                                <ul>
                                  {condition.status.map((s, i) => (
                                    <li key={`status_${index}_${i}`} className="--pre-wrap">
                                      {TODO_STATUS.find((t) => t.value === s)?.name ?? ""}
                                    </li>
                                  ))}
                                </ul>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ));
                    case "account":
                      return (
                        <Card className="MailSettingConditions-card">
                          <Card.Body>
                            <Row className="mb-2">
                              <Col xs={4}>
                                <div className="MailSettingConditions-card__name">ログインID</div>
                              </Col>
                              <Col xs={8}>
                                <div className="MailSettingConditions-card__name">備考</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <ul>
                                  {accounts.map((a) => (
                                    <li key={a.id} className="ms-3 --pre-wrap MailSettingConditions-card__account-item">
                                      {a.name}({a.login_code})
                                    </li>
                                  ))}
                                </ul>
                                <div className="mt-1 --pre-wrap">
                                  <Badge pill bg="info" className="me-2">
                                    !
                                  </Badge>
                                  <span>ログインIDと備考はAND条件です。</span>
                                </div>
                              </Col>
                              <Col xs={8}>
                                <div className="--pre-wrap">{selectedMailSetting.conditions[0].remarks} </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    default:
                      return null;
                  }
                })()}
              </ListGroup.Item>
            </ListGroup>
            {(selectedMailSetting.status === "waiting" || selectedMailSetting.status === "ready") && (
              <Row>
                <Col>
                  <h2 className="Headline--section">送信先一覧</h2>
                  <div className="mt-1">
                    <Badge pill bg="info" className="me-2">
                      !
                    </Badge>
                    <span>送信時に変動する場合があります。​</span>
                  </div>
                  <Table
                    col={[
                      {
                        name: "ログインID",
                        colName: "login_code",
                        width: 300,
                      },
                      {
                        name: "名前",
                        width: 300,
                      },
                      {
                        name: "メールアドレス",
                        width: 250,
                      },
                    ]}
                    row={sendMailTask.receivers.map((r) => {
                      return {
                        id: r.id,
                        key: r.id,
                        data: [r.login_code, r.name, r.mail_address],
                        appendAfter: {
                          login_code:
                            r.is_billing === false ? (
                              <div className="mx-1 badge rounded-pill bg-info">テスト用</div>
                            ) : r.is_guest ? (
                              <div className="mx-1 badge rounded-pill bg-success">ゲスト</div>
                            ) : null,
                        },
                      };
                    })}
                  />
                  <Button variant="outline-secondary" className="mt-2 float-end" disabled={true}>
                    さらに表示（全 　 件中 　 件表示中）
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;

import { useEffect, useMemo } from "react";
import { Container, Row, Col, Alert, Button, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getFileProjects, unselectFileProjects, selectFileState, downloadFile } from "./fileSlice";
import { selectUserState } from "../login/userSlice";
import Sidebar from "../../component/Sidebar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Icon from "../../component/Icon";
import { setLoading } from "../notification/notificationSlice";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const { fileProjects, fileProjectHasMore, fileProjectTotalCount, fileProjectFetchedPage } =
    useAppSelector(selectFileState);
  const { user } = useAppSelector(selectUserState);
  useEffect(() => {
    dispatch(getFileProjects({}));
    return () => {
      dispatch(unselectFileProjects());
    };
  }, []);

  const userTimeZone = useMemo(() => {
    return user.current_company.timezone;
  }, [user]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current="dashboard" />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">ファイルプロジェクト一覧</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                {fileProjects.length === 0 ? (
                  <Alert variant="info">ファイルプロジェクトがありません。</Alert>
                ) : (
                  <>
                    {fileProjects.map(({ id, name, description, start_time, end_time, files }) => {
                      const displyStartTime = dayjs(start_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                      const displyEndTime = dayjs(end_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                      return (
                        <Card className="my-1" key={id}>
                          <Card.Header>
                            {name}
                            {description && (
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 50, hide: 50 }}
                                overlay={(props) => (
                                  <Tooltip id={`tooltip_${id}`} {...props}>
                                    {description}
                                  </Tooltip>
                                )}
                              >
                                <span className="ms-1">
                                  <Icon width={15} height={15} type="info-circle-fill" />
                                </span>
                              </OverlayTrigger>
                            )}
                            <span className="--font-s">（{`${displyStartTime}~${displyEndTime}`}）</span>
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={async () => {
                                dispatch(setLoading(true));
                                await dispatch(downloadFile({ id, isAdmin: false }));
                                dispatch(setLoading(false));
                              }}
                            >
                              <Icon type="download" width={16} height={16} />
                              一括ダウンロード
                            </Button>
                          </Card.Header>
                          <Card.Body>
                            {files.map(({ name, key }) => {
                              return (
                                <div className="--bullet" key={key}>
                                  <a
                                    className="--inline-link --cursor-pointer"
                                    onClick={async () => {
                                      dispatch(setLoading(true));
                                      await dispatch(downloadFile({ id, key, isAdmin: false }));
                                      dispatch(setLoading(false));
                                    }}
                                  >
                                    {name}
                                  </a>
                                </div>
                              );
                            })}
                          </Card.Body>
                        </Card>
                      );
                    })}
                    <Button
                      variant="outline-secondary"
                      className="mt-2 float-end"
                      disabled={!fileProjectHasMore}
                      onClick={() => dispatch(getFileProjects({ page: fileProjectFetchedPage + 1 }))}
                    >
                      さらに表示（全 {fileProjectTotalCount} 件中 {fileProjects.length} 件表示中）
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;

export const MY_NUMBER_DISPLAY_MS = 5000;

export const MY_NUMBER_IMAGES = {
  number_file_id: {
    label: "番号確認資料",
    required: true,
  },
  identification_file_1_id: {
    label: "身分確認資料1",
    required: true,
  },
  identification_file_2_id: {
    label: "身分確認資料2",
    required: false,
    info: "顔写真のない証明書類の場合、身分確認資料が2点必要になります",
  },
} as {
  [key: string]: {
    label: string;
    required: boolean;
    info?: string;
  };
};

export type MyNumberFileColumn = "number_file_id" | "identification_file_1_id" | "identification_file_2_id";

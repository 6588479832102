import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const SLICE_NAME = "layout";
type mobileLayoutSidebarStatus = "open" | "folded";
interface LayoutState {
  mobileLayoutSidebar: mobileLayoutSidebarStatus;
  isTouchDevice: boolean;
}

const initialState: LayoutState = {
  mobileLayoutSidebar: "folded",
  isTouchDevice: document.ontouchstart !== undefined,
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMobileLayoutSidebar: (state, action: PayloadAction<mobileLayoutSidebarStatus>) => {
      state.mobileLayoutSidebar = action.payload;
    },
  },
});

export const { setMobileLayoutSidebar } = slice.actions;

export const selectLayoutState = (state: RootState) => {
  return state.layout as LayoutState;
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;

import { useAppDispatch, useAppSelector, ASSET_PATH } from "../../app/store";
import { Link } from "react-router-dom";
import { selectHasPostMasterPermissions, selectHasPostUserPermissions } from "../../features/login/userSlice";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import dayjs from "dayjs";
import { selectTodoState, getTodos } from "../todo/todoSlice";

function App() {
  const dispatch = useAppDispatch();
  const hasPostMasterPermissions = useAppSelector(selectHasPostMasterPermissions);
  const hasPostUserPermissions = useAppSelector(selectHasPostUserPermissions);
  const { todoCount } = useAppSelector(selectTodoState);
  const news = useMemo(() => {
    const today = dayjs();
    const recent25th = dayjs().date(25);
    if (today.date() < 25) {
      recent25th.subtract(1, "month");
    }
    return hasPostMasterPermissions
      ? [
          {
            label: "メンテナンス情報・お知らせ",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480649902",
          },
          {
            label: "管理者機能ガイド",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480748242/SHRPA",
          },
          // {
          //   label: "新しい機能が追加されました",
          //   tag: "system",
          //   date: dayjs(),
          //   isNew: true,
          //   link: "https://fosterlink.atlassian.net/wiki/spaces/GUIDES/pages/2612428801/HR-Platform+SHRPA",
          // },
          // {
          //   label: `${recent25th.format("YYYY年")}${recent25th.format("MM月DD日")}支給分 給与明細が発行されました`,
          //   tag: "event",
          //   date: recent25th,
          //   isNew: false,
          //   link: "/_/report/",
          // },
        ]
      : [
          {
            label: "メンテナンス情報・お知らせ",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480649902",
          },
          {
            label: "ユーザ機能ガイド",
            tag: "system",
            date: dayjs(),
            isNew: false,
            link: "https://fosterlink.atlassian.net/wiki/spaces/SHRPAPROFILE/pages/3480944845/SHRPA",
          },
        ];
  }, [hasPostMasterPermissions]);
  const dailyTasks = useMemo(() => {
    return hasPostUserPermissions
      ? [
          {
            label: "入社",
            to: "/_/dashboard/detail/#entry_to_company",
          },
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "退職",
          // },
          // {
          //   label: "転居",
          // },
          {
            label: "異動・昇進",
            to: "/_/dashboard/detail/#job_transfer",
          },
          // {
          //   label: "扶養家族変更",
          // },
          // {
          //   label: "書類出力",
          // },
        ]
      : [
          {
            label: "住所変更申請",
            to: "/_/apply/create/address/",
          },
          // {
          //   label: "書類出力",
          // },
        ];
  }, [hasPostUserPermissions]);
  const monthlyTasks = useMemo(() => {
    return hasPostUserPermissions
      ? [
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "住民税額の更新",
          //   description: "7/10 までに従業員情報を更新しましょう",
          // },
        ]
      : [];
  }, [hasPostUserPermissions]);
  const systemTasks = useMemo(() => {
    return hasPostMasterPermissions
      ? [
          {
            label: "アカウント",
            to: "/_/account/",
          },
          {
            label: "コード",
            to: "/_/masters/",
          },
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "ご契約中のプラン",
          // },
        ]
      : [];
  }, [hasPostMasterPermissions]);
  const profileTasks = useMemo(() => {
    return hasPostUserPermissions
      ? [
          {
            label: "プロファイル（一覧）",
            to: "/_/profiles/",
          },
          {
            label: "プロファイル（個別）",
            to: "/_/profile/",
          },
          // 2024/1～：ステージング環境で検証していただくため非表示
          // {
          //   label: "統計データ",
          // },
        ]
      : [
          {
            label: "プロファイル（個別）",
            to: "/_/profile/",
          },
        ];
  }, [hasPostUserPermissions]);

  useEffect(() => {
    // 件数がほしいだけなので、limit:1でリクエスト
    dispatch(getTodos({ limit: 1, page: 1, done: false }));
  }, []);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"dashboard"} />
      </div>
      <div className="Layout__main">
        <main>
          <Container>
            {news.length > 0 && (
              <Row className="mb-4">
                <Col>
                  <section className="News-card">
                    <h3 className="News-card__title">お知らせ</h3>
                    <div className="News-card__items">
                      {news.map((n, ni) => {
                        if (n.link) {
                          return (
                            <div className="News-card__item" key={`news${ni}`}>
                              {/* <div className="News-card__item-date">{n.date.format("YYYY/MM/DD")}</div> */}
                              {n.isNew && <div className="News-card__item-icon-new">New</div>}
                              <a href={n.link} target="_blank" className="News-card__item-label">
                                {n.label}
                              </a>
                            </div>
                          );
                        } else {
                          return (
                            <div className="News-card__item" key={`news${ni}`}>
                              {n.label}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </section>
                </Col>
              </Row>
            )}
            <div className="Dashboard-card__row">
              <section className="Dashboard-card my-1">
                <h2 className="Dashboard-card__title">通常業務</h2>
                <div className="Dashboard-card__buttons">
                  {dailyTasks.map((t, ti) => (
                    <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                      <div className="Dashboard-card__button-label">{t.label}</div>
                    </Link>
                  ))}
                </div>
              </section>
              <section className="Dashboard-card my-1">
                <h2 className="Dashboard-card__title">月例業務</h2>
                <div className="Dashboard-card__buttons">
                  {monthlyTasks.length > 0 ? (
                    monthlyTasks.map(() => (
                      <div></div>
                      // <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                      //   <div className="Dashboard-card__button-label">{t.label}</div>
                      // </Link>
                    ))
                  ) : (
                    <div>現在残っている業務はありません。</div>
                  )}
                </div>
              </section>
            </div>
            <div className="Dashboard-card__row">
              {profileTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">従業員情報</h2>
                  <div className="Dashboard-card__buttons">
                    {profileTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
              {systemTasks.length > 0 && (
                <section className="Dashboard-card my-1">
                  <h2 className="Dashboard-card__title">マスター管理</h2>
                  <div className="Dashboard-card__buttons">
                    {systemTasks.map((t, ti) => (
                      <Link to={t.to ?? ""} className="Dashboard-card__button" key={`task${ti}`}>
                        <div className="Dashboard-card__button-label">{t.label}</div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}
            </div>
            <div className="Dashboard-card__row">
              <section className="Dashboard-card my-1">
                <h2 className="Dashboard-card__title">ToDo</h2>
                <div className="Dashboard-card__buttons">
                  <Link to="/_/todo" className="Dashboard-card__button">
                    <div className="Dashboard-card__button-label">未完了のToDo（{todoCount}件）</div>
                  </Link>
                </div>
              </section>
            </div>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;

import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Table from "../../component/Table";
import { getAllTerms } from "../../app/translate";

const REPORTS = [
  {
    title: "SALARY_SLIP",
    reportType: "salary_slip",
  },
  {
    title: "BONUS_SLIP",
    reportType: "bonus_slip",
  },
];

function MasterReportList() {
  const TERMS = getAllTerms();

  return (
    <Container>
      <div className="pt-3">
        <Table
          col={[{ name: "レポート" }, { name: "アクション", width: 400 }]}
          row={REPORTS.map((report) => {
            return {
              data: [
                TERMS[report.title],
                <Link to={`/_/masters/reports/order/${report.reportType}`}>並び順の設定</Link>,
              ],
            };
          })}
        />
      </div>
    </Container>
  );
}

export default MasterReportList;

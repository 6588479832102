import { useEffect, useMemo } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getFileProjectsAdmin, unselectFileProjects, selectFileState } from "./fileSlice";
import { selectUserState } from "../login/userSlice";
import Table from "../../component/Table";
import Sidebar from "../../component/Sidebar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { fileProjects, fileProjectHasMore, fileProjectTotalCount, fileProjectFetchedPage } =
    useAppSelector(selectFileState);
  const { user } = useAppSelector(selectUserState);
  useEffect(() => {
    dispatch(getFileProjectsAdmin({}));
    return () => {
      dispatch(unselectFileProjects());
    };
  }, []);

  const userTimeZone = useMemo(() => {
    return user.current_company.timezone ?? "Asia/Tokyo";
  }, [user]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current="file" />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">ファイルプロジェクト一覧</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                <Button variant="outline-primary" onClick={() => navigate("/_/file_admin/create")}>
                  新規作成
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {fileProjects.length === 0 ? (
                  <Alert variant="info">ファイルプロジェクトがありません。</Alert>
                ) : (
                  <>
                    <Table
                      col={["プロジェクト名", "公開開始日時", "公開終了日時", "ファイル数"].map((name) => ({ name }))}
                      row={fileProjects.map(({ id, name, start_time, end_time, files }) => {
                        const displyStartTime = dayjs(start_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                        const displyEndTime = dayjs(end_time).tz(userTimeZone).format("YYYY/MM/DD HH:mm");
                        return {
                          data: [name, displyStartTime, displyEndTime, files.length],
                          link: `/_/file_admin/${id}`,
                        };
                      })}
                    />
                    <Button
                      variant="outline-secondary"
                      className="mt-2 float-end"
                      disabled={!fileProjectHasMore}
                      onClick={() => dispatch(getFileProjectsAdmin({ page: fileProjectFetchedPage + 1 }))}
                    >
                      さらに表示（全 {fileProjectTotalCount} 件中 {fileProjects.length} 件表示中）
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;

import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import Sidebar from "../../component/Sidebar";
import { MY_NUMBER_REGEX } from "../../app/validator";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  selectMyNumberState,
  getMyNumberSettings,
  getFamilyData,
  getMyNumber,
  selectMyNumber,
  clearMyNumberValue,
  clearSelectedMyNumber,
  attachMyNumberFile,
  deleteMyNumberFile,
  applyMyNumber,
} from "./myNumberSlice";
import { selectLayoutState } from "../layout/layoutSlice";
import ModalDialog from "../../component/ModalDialog";
import { selectProfileState, getMembers } from "../profile/profileSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { useNavigate, useParams } from "react-router-dom";
import PhotoCapturer from "../../component/PhotoCapturer";
import { MY_NUMBER_DISPLAY_MS, MY_NUMBER_IMAGES, MyNumberFileColumn } from "./myNumberValues";
import classNames from "classnames";
import Icon from "../../component/Icon";
import { setLoading } from "../notification/notificationSlice";
import { selectUserState } from "../login/userSlice";

function MyNumberDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUserState);
  const { accountId, myNumberId } = useParams();
  const {
    selectedMyNumber,
    textForEmployee,
    textForFamily,
    spouses,
    dependents,
    processing,
    myNumberValue,
    myNumberFiles,
  } = useAppSelector(selectMyNumberState);
  const { selfAccount, accounts } = useAppSelector(selectProfileState);
  const { isTouchDevice } = useAppSelector(selectLayoutState);
  const [currentValue, $currentValue] = useState("");
  const [repeatedName, $repeatedName] = useState("");
  const [activeModal, $activeModal] = useState("");
  const [selectedFileColumn, $selectedFileColumn] = useState("");
  const [entered, $entered] = useState({
    repeatedName: false,
    currentValue: false,
  });
  const [policyAgreedAt, $policyAgreedAt] = useState(null as number | null);
  const [files, $files] = useState({} as { [columnName: string]: DecodedFileData });
  const [selectedFileColumnName, $selectedFileColumnName] = useState(null as null | MyNumberFileColumn);
  const isNumberValid = useMemo(() => {
    return MY_NUMBER_REGEX.test(currentValue);
  }, [currentValue]);

  const isAdmin = useMemo(() => {
    return user.role === "admin";
  }, [user]);

  const _accountId = useMemo(() => {
    // 一般ユーザーの場合 path からは渡されない
    return accountId ? +accountId : selfAccount?.id;
  }, [accountId, selfAccount]);

  const onFileLoad = async (columName: MyNumberFileColumn, file: DecodedFileData) => {
    const fileId = selectedMyNumber?.[columName];
    if (!fileId) return;
    dispatch(setLoading(true));
    await dispatch(attachMyNumberFile({ fileId, decodedFileData: file }));
    $files({ ...files, [columName]: file });
    dispatch(setLoading(false));
  };
  const cancel = () => {
    navigate(-1);
  };
  const apply = async () => {
    if (!selectedMyNumber || !policyAgreedAt) return;
    dispatch(setLoading(true));
    await dispatch(
      applyMyNumber({
        id: selectedMyNumber.id,
        value: currentValue,
        agreed_at: policyAgreedAt,
      })
    );
    dispatch(setLoading(false));
    $activeModal("");
    navigate("/_/my_number/");
  };
  const commit = () => {
    $activeModal("");
  };
  const deleteFile = async (columName: MyNumberFileColumn) => {
    const fileId = selectedMyNumber?.[columName];
    if (!fileId) return;
    dispatch(setLoading(true));
    await dispatch(deleteMyNumberFile({ fileId }));
    const newFiles = { ...files };
    delete newFiles[columName];
    $files(newFiles);
    $activeModal("");
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (myNumberId) {
      dispatch(setLoading(true));
      dispatch(selectMyNumber({ id: myNumberId })).then(() => dispatch(setLoading(false)));
    }
    return () => {
      dispatch(clearSelectedMyNumber());
    };
  }, [myNumberId]);

  useEffect(() => {
    $files(myNumberFiles);
  }, [myNumberFiles]);

  useEffect(() => {
    if (selectedMyNumber) {
      const accountId = _accountId ? _accountId : selfAccount?.id;
      if (!accountId) return;
      if (selectedMyNumber.type === "self") {
        dispatch(getMembers({ accountId }));
      } else {
        dispatch(getFamilyData({ account_id: accountId }));
      }
    }
  }, [selectedMyNumber, selfAccount]);

  useEffect(() => {
    // マイナンバーの値は、一定時間表示された後にクリアする
    if (myNumberValue !== "") setTimeout(() => dispatch(clearMyNumberValue()), MY_NUMBER_DISPLAY_MS);
  }, [myNumberValue]);

  const relationship = useMemo(() => {
    if (selectedMyNumber?.type === "self") return "社員本人";
    if (selectedMyNumber?.type === "spouse") return "配偶者";
    if (selectedMyNumber?.type === "dependent") return "扶養家族";
    return "";
  }, [selectedMyNumber]);

  const isEditing = useMemo(() => {
    return selectedMyNumber?.status === "todo" || selectedMyNumber?.status === "rejected";
  }, [selectedMyNumber]);

  const targetName = useMemo(() => {
    if (selectedMyNumber?.type === "self") return accounts[0]?.name;
    if (selectedMyNumber?.type === "spouse")
      return spouses.find((s) => s.account_id === selectedMyNumber.account_id)?.spouse_name;
    if (selectedMyNumber?.type === "dependent")
      return dependents.find(
        (d) => d.account_id === selectedMyNumber.account_id && d.serial === selectedMyNumber.dependent_serial
      )?.dependent_name;
    return "";
  }, [selectedMyNumber, accounts, spouses, dependents]);

  const isValid = useMemo(() => {
    if (!selectedMyNumber) return false;
    if (isEditing) {
      return isNumberValid && files.number_file_id && files.identification_file_1_id && policyAgreedAt;
    } else if (selectedMyNumber.status === "reviewing") {
      return repeatedName === targetName;
    }
    return false;
  }, [selectedMyNumber, isNumberValid, files, repeatedName, policyAgreedAt]);
  const operationLabel = useMemo(() => {
    if (selectedMyNumber) {
      if (selectedMyNumber.status === "todo") return "登録";
      if (selectedMyNumber.status === "reviewing") return "承認";
      else if (selectedMyNumber.status === "rejected") return "再登録";
      else if (selectedMyNumber.status === "done") return "確認";
      return "";
    } else {
      return "";
    }
  }, [selectedMyNumber]);
  useEffect(() => {
    if (!textForEmployee) dispatch(getMyNumberSettings());
  }, [textForEmployee]);

  useEffect(() => {
    if (selectedMyNumber) $policyAgreedAt(selectedMyNumber.agreed_at);
  }, [selectedMyNumber]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー - {operationLabel}</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          {selectedMyNumber && (
            <Container>
              <Row className="--align-items-center">
                <Col md="3" className="--bold">
                  対象者
                </Col>
                <Col md="9">{targetName}</Col>
              </Row>
              <Row className="--align-items-center mt-2">
                <Col md="3" className="--bold">
                  関係性
                </Col>
                <Col md="9">{relationship}</Col>
              </Row>
              <Row className="--align-items-center mt-2">
                <Col
                  md="3"
                  className={classNames({
                    "--bold": true,
                    "--required-label": isEditing,
                  })}
                >
                  マイナンバー
                </Col>
                <Col md="9">
                  <Row>
                    <Col md={selectedMyNumber?.status === "reviewing" ? 9 : 12}>
                      <Form.Control
                        type="text"
                        maxLength={12}
                        placeholder=""
                        value={(() => {
                          if (myNumberValue !== "") return myNumberValue;
                          if (selectedMyNumber?.status === "reviewing") return "************";
                          return currentValue;
                        })()}
                        onChange={(e) => {
                          $currentValue(e.target.value);
                        }}
                        onBlur={() =>
                          $entered({
                            ...entered,
                            currentValue: true,
                          })
                        }
                        isInvalid={entered.currentValue && !isNumberValid}
                        disabled={selectedMyNumber?.status === "reviewing"}
                      />
                      {entered.currentValue && !isNumberValid && (
                        <Form.Control.Feedback type="invalid">
                          マイナンバーは半角数字12桁で入力してください。
                        </Form.Control.Feedback>
                      )}
                    </Col>
                    {selectedMyNumber?.status === "reviewing" && (
                      <Col>
                        <Button
                          onClick={() => {
                            if (!selectedMyNumber) return;
                            dispatch(getMyNumber({ id: selectedMyNumber.id }));
                          }}
                          variant="outline-primary"
                          className="mx-1"
                          disabled={processing || myNumberValue !== ""}
                        >
                          表示
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              {Object.keys(MY_NUMBER_IMAGES).map((columnName, index) => {
                const _columnName = columnName as
                  | "number_file_id"
                  | "identification_file_1_id"
                  | "identification_file_2_id";

                const uploaded = !!files[columnName]?.dataURI;
                const required = isEditing && MY_NUMBER_IMAGES[_columnName].required;
                return (
                  <Row className="--align-items-center mt-1" key={columnName}>
                    <Col
                      md="3"
                      className={classNames({
                        "--bold": true,
                        "--required-label": required,
                      })}
                    >
                      {MY_NUMBER_IMAGES[_columnName].label}
                      {MY_NUMBER_IMAGES[_columnName].info && (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 50, hide: 50 }}
                          overlay={(props) => (
                            <Tooltip id="tooltip" {...props}>
                              {MY_NUMBER_IMAGES[_columnName].info}
                            </Tooltip>
                          )}
                        >
                          <span className="ms-1">
                            <Icon width={15} height={15} type="info-circle-fill" />
                          </span>
                        </OverlayTrigger>
                      )}
                    </Col>
                    <Col md="9">
                      {uploaded ? (
                        <div className="my-1">
                          <div>
                            <div className="--flex --align-items-center">
                              <div className="--font-s">{files[columnName].name}</div>
                              <Button
                                disabled={selectedMyNumber?.status === "reviewing"}
                                onClick={() => {
                                  $selectedFileColumnName(_columnName);
                                  $activeModal("before_delete");
                                }}
                                variant="outline-danger"
                                size="sm"
                                className="mx-2"
                              >
                                削除
                              </Button>
                            </div>
                            <figure className="--attached-image --medium">
                              <Button
                                variant="link"
                                onClick={() => {
                                  $activeModal("image");
                                  $selectedFileColumn(columnName);
                                }}
                              >
                                <img src={`${files[columnName].dataURIprefix},${files[columnName].dataURI}`} />
                              </Button>
                            </figure>
                          </div>
                        </div>
                      ) : isEditing ? (
                        <div className="my-1">
                          <Uploader onFileLoad={(file) => onFileLoad(_columnName, file)} accepts={["image/*"]} />
                          {!isTouchDevice && (
                            <PhotoCapturer className="mt-1" onSave={(file) => onFileLoad(_columnName, file)} />
                          )}
                        </div>
                      ) : null}
                      {required && !uploaded && (
                        <div className="mt-1 --font-s --text-annotation">ファイルをアップロードしてください。</div>
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Row className="mt-4">
                <Col>
                  {(selectedMyNumber?.status === "todo" || selectedMyNumber?.status === "rejected") && (
                    <div className="Fixed-text-box mb-2">
                      {selectedMyNumber.type === "spouse" || selectedMyNumber.type === "dependent"
                        ? textForFamily
                        : textForEmployee}
                    </div>
                  )}
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="polocyConfirmed"
                      key="polocyConfirmed"
                      label={`マイナンバーポリシーに同意する${
                        selectedMyNumber?.status !== "todo"
                          ? `（${new Date(policyAgreedAt || 0).toLocaleString()} に同意済）`
                          : ""
                      }`}
                      disabled={selectedMyNumber?.status !== "todo"}
                      checked={!!policyAgreedAt}
                      onChange={() => $policyAgreedAt(policyAgreedAt ? null : new Date().getTime())}
                    />
                    {!policyAgreedAt && 
                      <div className="--font-s --text-annotation mt-1">文書をご確認の上、チェックを入れてください。</div>}
                  </div>
                </Col>
              </Row>
              {selectedMyNumber?.status === "reviewing" && (
                <div className="mt-4">
                  <div className="mb-2 text-sm">確認のため承認する対象者の名前を入力してください。</div>
                  <Row className="--align-items-center mt-2">
                    <Col md="3" className="--bold">
                      対象者名を入力
                    </Col>
                    <Col md="9">
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={repeatedName}
                        onChange={(e) => {
                          $repeatedName(e.target.value);
                        }}
                        onBlur={() =>
                          $entered({
                            ...entered,
                            repeatedName: true,
                          })
                        }
                        isInvalid={entered.repeatedName && repeatedName !== targetName}
                      />
                      {entered.repeatedName && repeatedName !== targetName && (
                        <Form.Control.Feedback type="invalid">名前が一致しません。</Form.Control.Feedback>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              <Row className="mt-4">
                <Col>
                  {selectedMyNumber?.status === "reviewing" && (
                    <Button onClick={() => $activeModal("before_commit")} disabled={!isValid} variant="primary">
                      承認
                    </Button>
                  )}
                  {selectedMyNumber?.status !== "reviewing" && (
                    <Button
                      onClick={() => {
                        if (isAdmin) {
                          // TODO: 管理者の場合は申請ではなく直接登録になる。後続で対応
                        } else {
                          $activeModal("before_apply");
                        }
                      }}
                      disabled={!isValid}
                      variant="primary"
                    >
                      {isAdmin ? "登録" : "申請"}
                    </Button>
                  )}
                  <Button onClick={() => cancel()} variant="outline-secondary" className="mx-2">
                    戻る
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
          <Modal show={activeModal === "image"} onHide={() => $activeModal("")} size="lg" centered>
            <Modal.Body>
              <figure className="mt-1 --attached-image --large">
                <img
                  src={
                    files[selectedFileColumn]
                      ? `${files[selectedFileColumn].dataURIprefix},${files[selectedFileColumn].dataURI}`
                      : ""
                  }
                />
              </figure>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => $activeModal("")} variant="outline-secondary">
                キャンセル
              </Button>
            </Modal.Footer>
          </Modal>
          <ModalDialog
            show={activeModal === "before_apply"}
            onCancel={() => $activeModal("")}
            onConfirm={apply}
            message={"申請します。よろしいですか？"}
          />
          <ModalDialog
            show={activeModal === "before_commit"}
            onCancel={() => {
              $activeModal("");
            }}
            onConfirm={commit}
            message={selectedMyNumber?.status === "todo" ? "登録しますか？" : "承認しますか？"}
          />
          <ModalDialog
            show={activeModal === "before_delete"}
            type="destructiveConfirm"
            onCancel={() => $activeModal("")}
            onConfirm={() => selectedFileColumnName && deleteFile(selectedFileColumnName)}
            message={"ファイルを削除します。よろしいですか？"}
            confirmButtonName="削除"
          />
          <ModalDialog
            show={activeModal === "error"}
            type="alert"
            onConfirm={() => {
              window.history.back();
            }}
            message={"処理中にエラーが発生しました。"}
          />
        </main>
      </div>
    </div>
  );
}

export default MyNumberDetail;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ReportTemplate, SearchCondition, Personal } from "./reportValues";
import { adminReportTemplates, userReportTemplates } from "./reportTemplates";
import { download } from "../../app/download";
import { call } from "../../app/api";
import dayjs from "dayjs";

const SLICE_NAME = "report";

export type ReportMetaData = { title: string; validFrom: string; downloadResource: string; accountId: number };

interface ReportState {
  adminInitialized: boolean;
  adminReportTemplates: ReportTemplate[];
  userInitialized: boolean;
  userReportTemplates: ReportTemplate[];
  personalData: Personal[];
  withholdingTaxMetaData: ReportMetaData[];
  salaryMetaData: ReportMetaData[];
  bonusMetaData: ReportMetaData[];
}

const initialState: ReportState = {
  adminInitialized: false,
  adminReportTemplates: [],
  userInitialized: false,
  userReportTemplates: [],
  personalData: [],
  withholdingTaxMetaData: [],
  salaryMetaData: [],
  bonusMetaData: [],
};

export const getAdminReportTemplates = createAsyncThunk(SLICE_NAME + "/getAdminReportTemplates", async () => {
  return adminReportTemplates;
});

export const getUserReportTemplates = createAsyncThunk(SLICE_NAME + "/getUserReportTemplates", async () => {
  return userReportTemplates;
});

export const bulkDownloadReport = createAsyncThunk(
  SLICE_NAME + "/bulkDownloadReport",
  async ({ report, baseDate, conditions }: { report: string; baseDate: dayjs.Dayjs; conditions: SearchCondition }) => {
    await call(
      "get",
      `report_admin_manager/${report}`
    )({
      ...conditions,
      base_date: baseDate.format("YYYY-MM-DD"),
    });
  }
);

export const singleDownloadReportByBaseDate = createAsyncThunk(
  SLICE_NAME + "/singleDownloadReportByBaseDate",
  async ({
    resource,
    accountId,
    baseDate,
    conditions,
  }: {
    resource: string;
    accountId: number;
    baseDate: dayjs.Dayjs;
    conditions?: SearchCondition;
  }) => {
    const res = await call(
      "get",
      `report_manager/${resource}`
    )({
      ...(conditions || {}),
      account_id: accountId,
      base_date: baseDate.format("YYYY-MM-DD"),
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const singleDownloadReportByValidFrom = createAsyncThunk(
  SLICE_NAME + "/singleDownloadReportByValidFrom",
  async ({
    resource,
    accountId,
    validFrom,
    conditions,
  }: {
    resource: string;
    accountId: number;
    validFrom: dayjs.Dayjs;
    conditions?: SearchCondition;
  }) => {
    const res = await call(
      "get",
      `report_manager/${resource}`
    )({
      ...(conditions || {}),
      account_id: accountId,
      valid_from: validFrom.format("YYYY-MM-DD"),
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const getPersonal = createAsyncThunk(
  SLICE_NAME + "/getPersonal",
  async ({ accountId, baseDate }: { accountId: number; baseDate: dayjs.Dayjs }) => {
    const res = await call(
      "get",
      "user_data_manager/personal"
    )({
      account_id: accountId,
      base_date: baseDate.format("YYYY-MM-DD"),
    });
    return res.data.result;
  }
);

export const getWithholdingTax = createAsyncThunk(
  SLICE_NAME + "/getWithholdingTax",
  async ({ accountId, before }: { accountId: number; before: dayjs.Dayjs }): Promise<ReportMetaData[]> => {
    /*
      - 源泉徴収情報
      - 源泉徴収受給者情報
      の両方に同じ基準日のレコードがあれば
      対応する源泉徴収票をダウンロードできるものと考える。
    */
    const [data1, data2] = await Promise.all([
      new Promise<any>((resolve, reject) => {
        call(
          "get",
          "user_data_manager/withholding_tax"
        )({
          account_id: accountId,
          valid_from__lte: before.format("YYYY-MM-DD"),
        })
          .then((res) => {
            resolve(res.data.result);
          })
          .catch(reject);
      }),
      new Promise<any>((resolve, reject) => {
        call(
          "get",
          "user_data_manager/withholding_tax_recipient"
        )({
          account_id: accountId,
          valid_from__lte: before.format("YYYY-MM-DD"),
        })
          .then((res) => {
            resolve(res.data.result);
          })
          .catch(reject);
      }),
    ]);
    // TODO: 一旦年１回の源泉徴収票のみ考えることとする
    const result = data1.reduce((prev: ReportMetaData[], current: any) => {
      const validFrom = current.valid_from;
      const corresponding = data2.find((d: any) => d.valid_from === validFrom);
      if (corresponding) {
        // TODO: 年号に変換する
        prev.push({
          title: dayjs(validFrom).format("YYYY 年分源泉徴収票"),
          validFrom,
          downloadResource: "withholding_tax_certificate_single_download",
          accountId,
        });
      }
      return prev;
    }, [] as ReportMetaData[]);
    return result.sort((a: ReportMetaData, b: ReportMetaData) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix());
  }
);

export const getSalaryAndBonus = createAsyncThunk(
  SLICE_NAME + "/getSalaryAndBonus",
  async ({ accountId, before }: { accountId: number; before: dayjs.Dayjs }): Promise<ReportMetaData[]> => {
    const salaries = await call(
      "get",
      "user_data_manager/salary"
    )({
      account_id: accountId,
      valid_from__lte: before.format("YYYY-MM-DD"),
    });
    const bonuses = await call(
      "get",
      "user_data_manager/bonus"
    )({
      account_id: accountId,
      valid_from__lte: before.format("YYYY-MM-DD"),
    });
    // salaryとbonusのresult配列をマージする
    const mergedResult = salaries.data.result.concat(bonuses.data.result);
    // 新しいオブジェクトを作成して返す
    const res = {
      data: {
        result: mergedResult,
        status: salaries.data.status,
      },
    };
    return res.data.result
      .map((r: any) => {
        let isSalary = "payment_bonus_base" in r ? false : true;
        let title = dayjs(r.valid_from).format("YYYY 年 MM 月支給分");
        return {
          title: isSalary ? title + " 給与明細" : title + " 賞与明細",
          validFrom: r.valid_from,
          downloadResource: isSalary ? "salary_slip_single_download" : "bonus_slip_single_download",
          accountId,
        } as ReportMetaData;
      })
      .sort((a: any, b: any) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix());
  }
);

export const getBonus = createAsyncThunk(
  SLICE_NAME + "/getBonus",
  async ({ accountId, before }: { accountId: number; before: dayjs.Dayjs }): Promise<ReportMetaData[]> => {
    const res = await call(
      "get",
      "user_data_manager/bonus"
    )({
      account_id: accountId,
      valid_from__lte: before.format("YYYY-MM-DD"),
    });
    return res.data.result
      .map((r: any) => {
        return {
          title: dayjs(r.valid_from).format("YYYY 年 MM 月支給分"),
          validFrom: r.valid_from,
          downloadResource: "bonus_slip_single_download",
          accountId,
        } as ReportMetaData;
      })
      .sort((a: any, b: any) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix());
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearPersonalData: (state) => {
      state.personalData = [];
      return state;
    },
    clearWithholdingTaxData: (state) => {
      state.withholdingTaxMetaData = [];
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminReportTemplates.fulfilled, (state, action) => {
      state.adminReportTemplates = action.payload;
      state.adminInitialized = true;
    });
    builder.addCase(getUserReportTemplates.fulfilled, (state, action) => {
      state.userReportTemplates = action.payload;
      state.userInitialized = true;
    });
    builder.addCase(getPersonal.fulfilled, (state, action) => {
      state.personalData = action.payload;
    });
    builder.addCase(getWithholdingTax.fulfilled, (state, action) => {
      state.withholdingTaxMetaData = action.payload;
    });
    builder.addCase(getSalaryAndBonus.fulfilled, (state, action) => {
      state.salaryMetaData = action.payload;
    });
    builder.addCase(getBonus.fulfilled, (state, action) => {
      state.bonusMetaData = action.payload;
    });
  },
});

export const { clearPersonalData, clearWithholdingTaxData } = slice.actions;

export const selectReportState = (state: RootState) => {
  return state.report as ReportState;
};

export const selectYearlyData = (state: RootState) => {
  const reportState = selectReportState(state);
  const { salaryMetaData, bonusMetaData, withholdingTaxMetaData } = reportState;
  const toGroup = (metaData: ReportMetaData[]) => {
    const groupedData = [] as { data: ReportMetaData[]; year: number }[];
    metaData.forEach((d) => {
      let target = groupedData.find((_) => _.year === +dayjs(d.validFrom).format("YYYY"));
      if (!target) {
        target = { data: [], year: dayjs(d.validFrom).year() };
        groupedData.push(target);
      }
      target.data.push(d);
    });
    return groupedData
      .map((group) => {
        return {
          data: group.data.sort((a, b) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix()),
          year: group.year,
        };
      })
      .sort((a, b) => b.year - a.year);
  };
  return {
    salaryMetaDataPerYear: toGroup(salaryMetaData),
    bonusMetaDataPerYear: toGroup(bonusMetaData),
    withholdingTaxMetaDataPerYear: toGroup(withholdingTaxMetaData),
  };
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;

import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers } from "../profile/profileSlice";
import MyNumberRow from "./MyNumberRow";
import MyNumberLogsModal from "./MyNumberLogsModal";
import {
  getMyNumber,
  getMyNumberViews,
  selectMyNumberViewPerAccount,
  selectMyNumberState,
  MyNumber,
  getFamilyData,
  clearMyNumberValue,
  postMyNumber,
  getStatusMessages,
} from "./myNumberSlice";
import Sidebar from "../../component/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { MY_NUMBER_DISPLAY_MS } from "./myNumberValues";

function MyNumberAdminDetailView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { myNumberViews, myNumberValue, statusMessages } = useAppSelector(selectMyNumberState);
  const views = useAppSelector(selectMyNumberViewPerAccount);
  const [displayRequestedId, $displayRequestedId] = useState<string>("");
  const [isLogModalActive, $isLogModalActive] = useState(false);
  const [targetMyNumber, $targetMyNumber] = useState<MyNumber | undefined>(undefined);
  const [targetAccountName, $targetAccountName] = useState("");

  useEffect(() => {
    if (accountId) {
      dispatch(getMembers({ accountId: +accountId }));
      dispatch(getMyNumberViews({ accountId: +accountId }));
      dispatch(getFamilyData({ account_id: +accountId }));
    }
  }, [accountId]);
  const matchedView = useMemo(() => {
    if (!accountId) return null;
    return views.find((a) => a.accountId === +accountId);
  }, [views, accountId]);

  const selfMyNumberViews = useMemo(() => {
    if (!accountId) return [];
    return myNumberViews.filter((a) => a.account_id === +accountId);
  }, [myNumberViews]);

  useEffect(() => {
    // 差し戻し時のメッセージを取得
    const rejectedMyNumberIds = selfMyNumberViews.filter((d) => d.status === "rejected").map((d) => d.id) as string[];
    if (rejectedMyNumberIds.length > 0) {
      dispatch(getStatusMessages({ myNumberIds: rejectedMyNumberIds }));
    }
  }, [selfMyNumberViews]);

  useEffect(() => {
    // マイナンバーの値は、一定時間表示された後にクリアする
    if (myNumberValue !== "") setTimeout(() => dispatch(clearMyNumberValue()), MY_NUMBER_DISPLAY_MS);
  }, [myNumberValue]);

  const handler = ({ id: myNumberId, action, name }: { id: string; action: string; name: string }) => {
    if (action === "browse") {
      $displayRequestedId(myNumberId);
      dispatch(getMyNumber({ id: myNumberId }));
    } else if (action === "register") {
      const dummyMyNumberView = (() => {
        const data = views.find((a) => a.accountId === matchedView?.accountId);
        if (!data) return;
        const matched = [data.self, data.spouse, ...data.dependent].find((d) => d.myNumber?.id === myNumberId);
        return matched?.myNumber;
      })();
      if (dummyMyNumberView?.id?.startsWith("_temp")) {
        dispatch(
          postMyNumber({
            accountId: matchedView?.accountId ?? 0,
            type: dummyMyNumberView?.type ?? "",
            dependentSerial: dummyMyNumberView?.dependent_serial ?? undefined,
          })
        ).then((res: any) => {
          const id = res.payload.id;
          navigate(`/_/my_number/admin/${matchedView?.accountId}/edit/${id}`);
        });
        return;
      } else if (dummyMyNumberView?.id) {
        navigate(`/_/my_number/admin/${matchedView?.accountId}/edit/${dummyMyNumberView.id}`);
      }
    } else if (action === "review") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      navigate(`/_/my_number/admin/${matchedView?.accountId}/edit/${matched.id}`);
    } else if (action === "list_logs") {
      const matched = myNumberViews.find((v) => v.id === myNumberId);
      if (!matched) return;
      $targetMyNumber(matched);
      $targetAccountName(name);
      $isLogModalActive(true);
    }
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      {matchedView && (
        <div className="Layout__main">
          <h1 className="Headline--page">マイナンバー - {matchedView.self.account?.name}</h1>
          <main className="mt-3 py-4 px-md-2 bg-white">
            <Container>
              <Row>
                <Col>
                  <Alert variant="info">マイナンバーについての操作履歴は全て記録されます。</Alert>
                </Col>
              </Row>
              {matchedView ? (
                <Row>
                  <Col>
                    <h2 className="Headline--section-sub">本人のマイナンバー</h2>
                    <MyNumberRow
                      id={matchedView.self.myNumber?.id ?? ""}
                      value={matchedView.self.myNumber?.id === displayRequestedId ? myNumberValue : ""}
                      name={matchedView.self.account?.name ?? ""}
                      status={matchedView.self.myNumber?.status ?? ""}
                      status_reason={statusMessages[matchedView.self.myNumber?.id ?? ""]}
                      canReview={true}
                      handler={handler}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row className="--align-items-center mt-4">
                <Col>
                  <h2 className="Headline--section-sub">配偶者・家族のマイナンバー</h2>
                  {matchedView.spouse.account.name || matchedView.dependent.some((d) => d.account.name) ? (
                    <div>
                      {matchedView.spouse?.account.name && matchedView.spouse?.myNumber && (
                        <MyNumberRow
                          id={matchedView.spouse.myNumber.id}
                          value={matchedView.spouse.myNumber.id === displayRequestedId ? myNumberValue : ""}
                          name={matchedView.spouse.account.name}
                          status={matchedView.spouse.myNumber.status}
                          status_reason={statusMessages[matchedView.spouse.myNumber.id]}
                          canReview={true}
                          handler={handler}
                          className="mb-1"
                        />
                      )}
                      {matchedView.dependent.length > 0 &&
                        matchedView.dependent.map((d) => {
                          if (!d.account || !d.myNumber) return null;
                          return (
                            <MyNumberRow
                              key={d.myNumber.id}
                              id={d.myNumber.id}
                              value={d.myNumber.id === displayRequestedId ? myNumberValue : ""}
                              name={d.account.name}
                              status={d.myNumber.status}
                              status_reason={statusMessages[d.myNumber.id]}
                              canReview={true}
                              handler={handler}
                              className="mb-1"
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <span>配偶者・家族は登録されていません。</span>
                  )}
                </Col>
              </Row>
              <MyNumberLogsModal
                isActive={isLogModalActive}
                myNumber={targetMyNumber}
                name={targetAccountName}
                onClose={() => {
                  $isLogModalActive(false);
                  $targetAccountName("");
                }}
              />
            </Container>
          </main>
        </div>
      )}
    </div>
  );
}

export default MyNumberAdminDetailView;

import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import "react-calendar/dist/Calendar.css";
import { deletionPeriods, getMyNumberSettings, selectMyNumberState } from "../myNumber/myNumberSlice";

function MyNumberConfig() {
  const dispatch = useAppDispatch();
  const { textForEmployee, textForFamily, doDeleteFiles, deletionPeriod, notificationTemplate } =
    useAppSelector(selectMyNumberState);
  const [isUnsavedChanges, $isUnsavedChanges] = useState(false);

  const [periods, $periods] = useState<{ key: string; title: string; selected: boolean }[]>(
    deletionPeriods.map((period, i) => {
      return {
        ...period,
        selected: i === 0,
      };
    })
  );
  const selectedPeriod = useMemo(() => {
    return periods.find((period) => period.selected) ?? { key: "empty", title: "", selected: false };
  }, [periods]);

  const [next, $next] = useState({
    textForEmployee: "",
    textForFamily: "",
    doDeleteFiles: true,
    notificationTemplate: "",
  });

  useEffect(() => {
    dispatch(getMyNumberSettings());
  }, []);
  useEffect(() => {
    $next({
      textForEmployee,
      textForFamily,
      doDeleteFiles,
      notificationTemplate,
    });
    $periods(periods.map((period) => ({ ...period, selected: period.key === deletionPeriod })));
  }, [textForEmployee, textForFamily, doDeleteFiles, deletionPeriod, notificationTemplate]);

  const commit = () => {
    return false;
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <div>
            <Link to={"/_/my_number/download_log"}>
              <Button variant="outline-primary">一括ダウンロードログを表示</Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md={6}>退職者または削除予定としたマイナンバーが完全に削除されるまでの期間</Col>
        <Col md={6}>
          <Form.Select
            value={selectedPeriod.key}
            onChange={(e) => {
              $periods(periods.map((period) => ({ ...period, selected: period.key === e.target.value })));
              $isUnsavedChanges(true);
            }}
          >
            {periods.map((period, i) => {
              return (
                <option key={`period_${i}`} value={period.key}>
                  {period.title}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md={6}>確認資料の保管</Col>
        <Col md={6}>
          <Form.Check
            inline
            type="checkbox"
            id={`fileDeletion`}
            label={"マイナンバー確認後に確認資料を破棄する"}
            checked={next.doDeleteFiles}
            onChange={() => {
              $next({ ...next, doDeleteFiles: !next.doDeleteFiles });
              $isUnsavedChanges(true);
            }}
          ></Form.Check>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>利用目的（社員本人向け）</Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.textForEmployee}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                textForEmployee: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>利用目的（配偶者・家族向け）</Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.textForFamily}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                textForFamily: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>一括提出依頼のデフォルト文面</Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.notificationTemplate}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                notificationTemplate: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div>
            <Button onClick={commit} disabled={!isUnsavedChanges} variant="primary">
              保存
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default MyNumberConfig;

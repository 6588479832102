import { store } from "./store";
import { default as client } from "../features/client/clientDictionary";
import { default as login } from "../features/login/loginDictionary";
import { default as apply } from "../features/apply/applyDictionary";
import { default as todo } from "../features/todo/todoDictionary";
import { default as profile } from "../features/profile/profileDictionary";
import { default as report } from "../features/report/reportDictionary";

export type LangDictionary = {
  [key: string]: { [lang: string]: string };
};

const Dictionary = {
  ...client,
  ...login,
  ...apply,
  ...todo,
  ...profile,
  ...report,
} as LangDictionary;

const LangList = {
  ja: "ja",
  en: "en",
  "en-US": "en",
  "en-GB": "en",
  cn: "en",
} as { [key: string]: string };

const getBrowserLanguage = (): string => {
  return (navigator.languages && navigator.languages[0]) || navigator.language;
};

const getLang = (): string => {
  const { user } = store.getState();
  const language = user.user.language || getBrowserLanguage();
  return LangList[language] ?? "en";
};

const getDictionary = (lang: string) => {
  let dictionary = {};
  for (const key in Dictionary) {
    dictionary = {
      ...dictionary,
      [key]: Dictionary[key][lang],
    };
  }
  return dictionary;
};

let _lang = getLang();
let _dictionary = getDictionary(_lang);

export const getAllTerms = (): { [key: string]: string } => {
  const lang = getLang();
  if (lang !== _lang) {
    _lang = lang;
    _dictionary = getDictionary(lang);
    return _dictionary;
  }
  return _dictionary;
};

import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Button, Alert, Modal, Form, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMembers, selectProfileState } from "../profile/profileSlice";
import {
  getMyNumberViews,
  getFamilyData,
  getResignee,
  selectMyNumberViewPerAccount,
  selectMyNumberState,
  myNumberStatusDictionary,
  MyNumberViewWithAccount,
  getMyNumberSettings,
} from "../myNumber/myNumberSlice";
import ModalDialog from "../../component/ModalDialog";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import Icon from "../../component/Icon";
import dayjs from "dayjs";

const myNumberStatusList = Object.keys(myNumberStatusDictionary).reduce((prev, current) => {
  return [...prev, { label: myNumberStatusDictionary[current], value: current }];
}, [] as { label: string; value: string }[]);

function MyNumberListView() {
  const dispatch = useAppDispatch();
  const { accountLoading, allAccountsLoaded } = useAppSelector(selectProfileState);
  const { notificationTemplate } = useAppSelector(selectMyNumberState);
  const myNumberViewPerAccount = useAppSelector(selectMyNumberViewPerAccount);
  const [modalErrorMessage, $modalErrorMessage] = useState("");
  const [bulkTargets, $bulkTargets] = useState<MyNumberViewWithAccount[]>([]);
  const [activeModal, $activeModal] = useState("");
  const [status1, $status1] = useState(
    myNumberStatusList.map((o: { label: string; value: string }) => ({
      label: o.label,
      value: o.value,
      checked: false,
    }))
  );
  const [status2, $status2] = useState(
    [
      { label: "社員本人", value: "self" },
      { label: "配偶者", value: "spouse" },
      { label: "家族", value: "dependent" },
    ].map((o: { label: string; value: string }) => ({
      label: o.label,
      value: o.value,
      checked: false,
    }))
  );
  const [dateOptions, $dateOptions] = useState([
    {
      label: "1週間以内",
      value: 1,
      checked: false,
    },
    {
      label: "1週間以上前",
      value: 2,
      checked: false,
    },
    {
      label: "1か月以上前",
      value: 3,
      checked: false,
    },
  ]);
  const [otherOptions, $otherOptions] = useState([
    {
      label: "退職者を表示する",
      value: "showRetired",
      checked: false,
    },
  ]);
  const [checkedIds, $checkedIds] = useState([] as string[]);
  const [showDownloadModal, $showDownloadModal] = useState(false);
  const [oneTimePassword, $oneTimePassword] = useState("");
  const [downloadPurpose, $downloadPurpose] = useState("");
  const [mailSent, $mailSent] = useState(false);
  const [downloadError, $downloadError] = useState("");
  const [messageBody, $messageBody] = useState("");

  const filteringConditionsText = useMemo(() => {
    if ([...status1, ...status2, ...dateOptions, ...otherOptions].filter((o) => o.checked).length === 0) return "なし";
    let filteringConditionsText = "";
    if (status1.some((o) => o.checked)) {
      filteringConditionsText += `ステータス : ${status1
        .filter((o) => o.checked)
        .map((s) => s.label)
        .join(",")}`;
    }
    if (status2.some((o) => o.checked)) {
      filteringConditionsText = filteringConditionsText ? `${filteringConditionsText} / ` : "";
      filteringConditionsText += `関係性 : ${status2
        .filter((o) => o.checked)
        .map((s) => s.label)
        .join(",")}`;
    }
    if (dateOptions.some((o) => o.checked)) {
      filteringConditionsText = filteringConditionsText ? `${filteringConditionsText} / ` : "";
      filteringConditionsText += `申請日 : ${dateOptions
        .filter((o) => o.checked)
        .map((s) => s.label)
        .join(",")}`;
    }
    if (otherOptions.some((o) => o.checked)) {
      filteringConditionsText = filteringConditionsText ? `${filteringConditionsText} / ` : "";
      filteringConditionsText += `その他 : ${otherOptions
        .filter((o) => o.checked)
        .map((s) => s.label)
        .join(",")}`;
    }
    return filteringConditionsText;
  }, [status1, status2, dateOptions, otherOptions]);

  const filteredData = useMemo(() => {
    const someChecked = {
      status1: status1.some((s) => s.checked),
      status2: status2.some((s) => s.checked),
      dateOptions: dateOptions.some((s) => s.checked),
      otherOptions: otherOptions.some((s) => s.checked),
    } as { status1: boolean; status2: boolean; dateOptions: boolean; otherOptions: boolean };

    return myNumberViewPerAccount.map((current) => {
      const filtered = {
        accountId: current.accountId,
        self: {
          myNumber: null,
          account: current.self.account,
        },
        spouse: {
          myNumber: null,
          account: current.self.account,
        },
        dependent: [],
      } as {
        accountId: number;
        self: MyNumberViewWithAccount | null;
        spouse: MyNumberViewWithAccount | null;
        dependent: MyNumberViewWithAccount[];
      };
      [current.self, current.spouse, ...current.dependent].forEach((d, i) => {
        if (someChecked.status1 && status1.find((s) => s.value === d.myNumber?.status)?.checked === false) {
          return;
        }
        if (someChecked.status2 && status2.find((s) => s.value === d.myNumber?.type)?.checked === false) {
          return;
        }
        if (someChecked.dateOptions) {
          let matched = false;
          if (dateOptions[0].checked && dayjs(d.myNumber?.applied_at).isAfter(dayjs().add(-1, "week"))) matched = true;
          if (dateOptions[1].checked && dayjs(d.myNumber?.applied_at).isBefore(dayjs().add(-1, "week"))) matched = true;
          if (dateOptions[2].checked && dayjs(d.myNumber?.applied_at).isBefore(dayjs().add(-1, "month")))
            matched = true;
          if (!matched) return;
        }

        if (someChecked.otherOptions) {
          if (otherOptions[0].checked) {
            // 全て表示
          }
        } else {
          if (d.account.retired) return;
        }

        if (i === 0) {
          filtered.self = d;
        } else if (i === 1) {
          filtered.spouse = d;
        } else {
          filtered.dependent.push(d);
        }
      });
      return filtered;
    });
  }, [myNumberViewPerAccount, status1, status2, dateOptions, otherOptions]);

  useEffect(() => {
    dispatch(getMembers());
    dispatch(getMyNumberViews());
    dispatch(getFamilyData());
    dispatch(getResignee());
    dispatch(getMyNumberSettings());
  }, []);

  useEffect(() => {
    $messageBody(notificationTemplate);
  }, [notificationTemplate]);

  const issueOnetimePassword = () => {
    $mailSent(true);
  };
  const downloadWithOneTimePassword = () => {
    $downloadError("");
    setTimeout(() => {
      $downloadError("ワンタイムパスワードが有効期限切れとなっています。");
    }, 1000);
  };

  const bulkDelete = () => {
    const deletableData = filteredData
      .map((f) => {
        return [f.self, f.spouse, ...f.dependent]
          .filter((_) => _)
          .filter((_) => !["unregistered", "deletable"].some((status) => status === _?.myNumber?.status))
          .filter((_) => checkedIds.some((id) => id === _?.myNumber?.id)) as MyNumberViewWithAccount[];
      })
      .filter((list) => list.length > 0)
      .flat();
    if (deletableData.length === 0) {
      $modalErrorMessage("削除可能なステータスのマイナンバーが選択されていません。");
      return;
    }
    $bulkTargets(deletableData);
    $activeModal("bulkDelete");
  };

  const bulkAnnounce = () => {
    const registerableData = filteredData
      .map((f) => {
        return [f.self, f.spouse, ...f.dependent]
          .filter((_) => _)
          .filter((_) => ["unregistered", "rejected"].some((status) => status === _?.myNumber?.status))
          .filter((_) => checkedIds.some((id) => id === _?.myNumber?.id)) as MyNumberViewWithAccount[];
      })
      .filter((list) => list.length > 0)
      .flat();
    if (registerableData.length === 0) {
      $modalErrorMessage("登録可能なステータスのマイナンバーが選択されていません。");
      return;
    }
    $bulkTargets(registerableData);
    $activeModal("bulkAnnounce");
  };

  const commitBulkDelete = () => {
    $activeModal("");
  };

  const commitBulkRegister = () => {
    $activeModal("");
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">マイナンバー</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row className="mt-2 mb-4">
              <Col md="2">
                <div className="--bold pt-md-3">絞込条件</div>
              </Col>
              <Col md="10">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{filteringConditionsText}</Accordion.Header>
                    <Accordion.Body>
                      <Row className="--align-items-center mb-1">
                        <Col md={4}>ステータス：</Col>
                        <Col md={8}>
                          {status1.map((o, i) => (
                            <Form.Check
                              type="checkbox"
                              key={`check-a${i}`}
                              id={`check-a${i}`}
                              label={o.label}
                              checked={o.checked}
                              onChange={() => {
                                $status1(
                                  status1.map((_o) => {
                                    return {
                                      ..._o,
                                      checked: _o.label === o.label ? !o.checked : _o.checked,
                                    };
                                  })
                                );
                              }}
                              inline
                            />
                          ))}
                        </Col>
                      </Row>
                      <Row className="--align-items-center mb-1">
                        <Col md={4}>関係性：</Col>
                        <Col md={8}>
                          {status2.map((o, i) => (
                            <Form.Check
                              type="checkbox"
                              key={`check-b${i}`}
                              id={`check-b${i}`}
                              label={o.label}
                              checked={o.checked}
                              onChange={() => {
                                $status2(
                                  status2.map((_o) => {
                                    return {
                                      ..._o,
                                      checked: _o.label === o.label ? !o.checked : _o.checked,
                                    };
                                  })
                                );
                              }}
                              inline
                            />
                          ))}
                        </Col>
                      </Row>
                      <Row className="--align-items-center mb-1">
                        <Col md={4}>申請日：</Col>
                        <Col md={8}>
                          {dateOptions.map((o, i) => (
                            <Form.Check
                              type="checkbox"
                              key={`check-c${i}`}
                              id={`check-c${i}`}
                              label={o.label}
                              checked={o.checked}
                              onChange={() => {
                                $dateOptions(
                                  dateOptions.map((_o) => {
                                    return {
                                      ..._o,
                                      checked: _o.label === o.label ? !o.checked : _o.checked,
                                    };
                                  })
                                );
                              }}
                              inline
                            />
                          ))}
                        </Col>
                      </Row>
                      <Row className="--align-items-center mb-1">
                        <Col md={4}>その他：</Col>
                        <Col md={8}>
                          {otherOptions.map((o, i) => (
                            <Form.Check
                              type="checkbox"
                              key={`check-o${i}`}
                              id={`check-o${i}`}
                              label={o.label}
                              checked={o.checked}
                              onChange={() => {
                                $otherOptions(
                                  otherOptions.map((_o) => {
                                    return {
                                      ..._o,
                                      checked: _o.label === o.label ? !o.checked : _o.checked,
                                    };
                                  })
                                );
                              }}
                              inline
                            />
                          ))}
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <div className="--flex justify-content-between">
                  <div>
                    <Button onClick={() => $showDownloadModal(true)} variant="outline-primary" className="mx-1">
                      ダウンロード
                    </Button>
                    <Link to={"/_/my_number/upload"} className="mx-1">
                      <Button variant="outline-primary">アップロード</Button>
                    </Link>
                  </div>
                  <div className="--flex">
                    <Button
                      variant="outline-primary"
                      disabled={checkedIds.length === 0}
                      onClick={bulkAnnounce}
                      className="mx-1"
                    >
                      一括提出依頼
                    </Button>
                    <Button
                      variant="outline-danger"
                      disabled={checkedIds.length === 0}
                      onClick={bulkDelete}
                      className="mx-1"
                    >
                      一括削除
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {accountLoading && myNumberViewPerAccount.length === 0 && (
              <div className="--flex --align-items-center --text-light-color">
                <div>
                  <Icon type="box-arrow-down" width={24} height={24} />
                </div>
                <div className="--px-2">
                  データをダウンロード中です。データ総数によっては 10
                  秒以上かかる場合がありますが、この間他の画面に移動しても問題ありません。
                </div>
              </div>
            )}
            {allAccountsLoaded && filteredData.length > 0 ? (
              <Table
                col={[
                  {
                    name: "氏名",
                    colName: "name",
                    filterable: true,
                  },
                  {
                    name: "",
                    width: 120,
                  },
                  {
                    name: "関係性",
                    width: 80,
                  },
                  {
                    name: "ステータス",
                  },
                  {
                    name: "登録日時",
                  },
                  {
                    name: "承認日時",
                  },
                  {
                    name: "差し戻し日時",
                  },
                  {
                    name: "削除予定の設定日時",
                  },
                ]}
                row={filteredData
                  .map((a: any) => {
                    const family = [a.self, a.spouse, ...a.dependent].filter((_) => _.myNumber);
                    return family.map((f) => ({
                      id: f.myNumber.id,
                      key: f.myNumber.id,
                      data: [
                        <Link to={`/_/my_number/admin/${a.accountId}`}>{f.account.name}</Link>,
                        a.self.myNumber?.id === f.myNumber.id ? (
                          <Link to={`/_/profile/${a.accountId}`}>プロファイル</Link>
                        ) : (
                          ""
                        ),
                        (() => {
                          switch (true) {
                            case a.self.myNumber?.id === f.myNumber.id:
                              return "社員本人";
                            case a.spouse.myNumber?.id === f.myNumber.id:
                              return "配偶者";
                            case a.dependent.some((d: any) => d.myNumber?.id === f.myNumber.id):
                              return "家族";
                            default:
                              return "";
                          }
                        })(),
                        `${myNumberStatusDictionary[f.myNumber?.status ?? ""]}${f.account.retired ? "（退職）" : ""}`,
                        f.myNumber.applied_at ? dayjs(f.myNumber.applied_at).format("YYYY/MM/DD HH:mm") : "",
                        f.myNumber.approved_at ? dayjs(f.myNumber.approved_at).format("YYYY/MM/DD HH:mm") : "",
                        f.myNumber.rejected_at ? dayjs(f.myNumber.rejected_at).format("YYYY/MM/DD HH:mm") : "",
                        f.myNumber.expired_at ? dayjs(f.myNumber.expired_at).format("YYYY/MM/DD HH:mm") : "",
                      ],
                    }));
                  })
                  .flat()}
                usePagenation={true}
                useKeywordFilter={true}
                onCheck={(next) => {
                  $checkedIds(next as string[]);
                }}
                checkedIds={checkedIds}
              />
            ) : (
              !accountLoading &&
              allAccountsLoaded && <Alert variant={"info"}>該当するアカウント情報がありません。</Alert>
            )}
          </Container>
        </div>
      </div>
      <Modal show={showDownloadModal} onHide={() => $showDownloadModal(false)} size="lg" centered>
        <Modal.Body>
          <Row className="--align-items-center">
            <Col>
              <div className="--bold">ワンタイムパスワードの発行</div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>
              <div className="pt-2">マイナンバーの利用目的</div>
            </Col>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder=""
                value={downloadPurpose}
                onChange={(e) => {
                  $downloadPurpose(e.target.value);
                }}
              />
              <Button
                disabled={!downloadPurpose}
                variant="outline-primary"
                onClick={issueOnetimePassword}
                className="mt-2"
              >
                発行する
              </Button>
              {mailSent && (
                <Alert variant="info" className="mt-2 --font-s">
                  ワンタイムパスワードをメールで送信しました。下の欄に入力するとダウンロードできます。
                </Alert>
              )}
            </Col>
          </Row>
          <Row className="--align-items-center mt-4">
            <Col>
              <div className="--bold">ダウンロード</div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>ワンタイムパスワードの入力</Col>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder=""
                value={oneTimePassword}
                onChange={(e) => {
                  $oneTimePassword(e.target.value);
                }}
              />
              <Button
                disabled={!oneTimePassword}
                variant="primary"
                onClick={downloadWithOneTimePassword}
                className="mt-2"
              >
                ダウンロードを実行
              </Button>
              {downloadError && (
                <Alert variant="danger" className="mt-2 --font-s">
                  {downloadError}
                </Alert>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => $showDownloadModal(false)}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={activeModal === "bulkDelete" || activeModal === "bulkAnnounce"}
        onHide={() => $activeModal("")}
        size="lg"
        centered
      >
        <Modal.Body>
          <Row className="--align-items-center">
            <Col>
              <div className="--bold">
                {activeModal === "bulkDelete" ? "マイナンバーの一括削除" : "マイナンバーの一括提出依頼"}
              </div>
            </Col>
          </Row>
          <Row className="mt-4 --align-items-center">
            <Col md={3}>
              <div className="--bold">対象者</div>
            </Col>
            <Col md={9}>
              {bulkTargets.map((b, i) => {
                if (i > 20) return;
                return (
                  <span key={`target${i}`} className="px-1">
                    {b.account.name}
                  </span>
                );
              })}
              {bulkTargets.length > 20 && <span>...</span>}
              <span className="mx-2">
                （合計: <span className="--bold">{bulkTargets.length}</span> 名）
              </span>
            </Col>
          </Row>
          {activeModal === "bulkAnnounce" && (
            <Row className="mt-2 --align-items-center">
              <Col md={3}>
                <div className="--bold">文面</div>
              </Col>
              <Col md={9}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  value={messageBody}
                  style={{ height: "150px" }}
                  onChange={(e) => $messageBody(e.target.value)}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="--flex">
            <Button variant="outline-secondary" onClick={() => $activeModal("")} className="mx-2">
              キャンセル
            </Button>
            <Button
              variant={activeModal === "bulkDelete" ? "danger" : "primary"}
              onClick={activeModal === "bulkDelete" ? commitBulkDelete : commitBulkRegister}
            >
              {activeModal === "bulkDelete" ? "一括削除" : "一括送信"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={!!modalErrorMessage}
        onConfirm={() => $modalErrorMessage("")}
        message={modalErrorMessage}
        type="alert"
      />
    </div>
  );
}

export default MyNumberListView;

import { ProfileFileElement } from "../profile/profileFieldValues";

export type FileProject = {
  id: string;
  name: string;
  description?: string;
  start_time: number;
  end_time: number;
  display_type: "public" | "private";
  files: ProfileFileElement[];
  errors?: { [fileName: string]: string[] };
};

export const GET_FILE_PROJECTS_LIMIT = 10;

export const FILE_NAME_FORBIDDEN_CHARS = ["\\", "/", ":", "*", "?", '"', "<", ">", "|"];

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const FILE_ERROR_MESSAGE_MAP = {
  "W038 name is/are duplicated.": "ファイル名が重複しています",
  "W002 param `name` and value is/are required!": "ファイル名が空です",
  "W003 param `file.size` is/are invalid": "ファイルサイズが5MBを超えています",
} as { [key: string]: string };

import { Form, Col, Row, Card } from "react-bootstrap";
import { Account } from "./applyValues";
import { useEffect, useState } from "react";
import { clearFilteredAccounts, searchAccounts, selectApplyState } from "./applySlice";
import { useAppDispatch, useAppSelector } from "../../app/store";

function ProcessorSelector({
  selectedAccountIds,
  checkedAccounts,
  onChange,
}: {
  selectedAccountIds: number[];
  checkedAccounts: Account[];
  onChange: (checkedAccounts: Account[]) => any;
}) {
  const { filteredAccounts } = useAppSelector(selectApplyState);
  const [state, $state] = useState({
    keyword: "",
    timeoutId: null as number | null,
  });
  const dispatch = useAppDispatch();

  const onChangeKeyWord = (keyword: string) => {
    // 入力が終わって500ms後に検索のリクエストをする
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      if (keyword) dispatch(searchAccounts(keyword));
      else dispatch(clearFilteredAccounts());
      $state({ ...state, timeoutId: null, keyword });
    }, 500);

    $state({ ...state, timeoutId, keyword });
  };

  const checkAccount = (account: Account) => {
    // アカウントにチェックされた
    const next = checkedAccounts.some((_) => _.id === account.id)
      ? checkedAccounts.filter((_) => _.id !== account.id)
      : [...checkedAccounts, account];
    onChange(next);
  };

  useEffect(() => {
    return () => {
      dispatch(clearFilteredAccounts());
    };
  }, []);

  return (
    <div>
      <Card className="mb-1">
        <Card.Header>選択中のアカウント</Card.Header>
        <Card.Body>{checkedAccounts.length ? checkedAccounts.map((a) => a.label).join(", ") : "（なし）"}</Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Form.Control
            type="text"
            id="search"
            value={state.keyword}
            className="mb-1"
            placeholder="キーワード（名前・ログインID）で絞り込む"
            onChange={(e) => {
              onChangeKeyWord(e.target.value);
            }}
          />
          <Row>
            {filteredAccounts.map((account) => {
              // モーダル開く前に選択済
              const isAlreadySelected = selectedAccountIds.includes(account.id);
              // モーダル開いてから選択
              const isSelecting = checkedAccounts.some(({ id }) => id === account.id);
              return (
                <Col md="6" key={`modalAccount${account.id}`}>
                  <Form.Check
                    checked={isAlreadySelected || isSelecting}
                    id={`modalAccount${account.id}`}
                    type="checkbox"
                    disabled={isAlreadySelected}
                    label={account.label}
                    onChange={() => checkAccount(account)}
                  />
                </Col>
              );
            })}
            {filteredAccounts.length === 0 && (
              <Col>該当するアカウントが存在しません。キーワードを変更してください。</Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProcessorSelector;
